"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Images = void 0;
exports.Images = {
    zero: {
        name: "Zero",
        colors: [],
    },
    "bg-cool": {
        name: "Cool",
        colors: ["d5d7e1"],
    },
    "bg-warm": {
        name: "Warm",
        colors: ["e1d7d5"],
    },
    "body-bege-bsod": {
        name: "Beige",
        colors: ["c5b9a1"],
    },
    "body-bege-crt": {
        name: "Beige Light",
        colors: ["cfc2ab"],
    },
    "body-blue-sky": {
        name: "Blue Sky",
        colors: ["63a0f9"],
    },
    "body-bluegrey": {
        name: "Bluegrey",
        colors: ["807f7e"],
    },
    "body-cold": {
        name: "Cold",
        colors: ["caeff9"],
    },
    "body-computerblue": {
        name: "Computer Blue",
        colors: ["5648ed"],
    },
    "body-darkbrown": {
        name: "Dark Brown",
        colors: ["5a423f"],
    },
    "body-darkpink": {
        name: "Dark Pink",
        colors: ["b9185c"],
    },
    "body-foggrey": {
        name: "Fog Grey",
        colors: ["cbc1bc"],
    },
    "body-gold": {
        name: "Gold",
        colors: ["b87b11"],
    },
    "body-grayscale-1": {
        name: "White",
        colors: ["fffdf2"],
    },
    "body-grayscale-7": {
        name: "Dark Grey",
        colors: ["4b4949"],
    },
    "body-grayscale-8": {
        name: "Darker Grey",
        colors: ["343235"],
    },
    "body-grayscale-9": {
        name: "Darkest Grey",
        colors: ["1f1d29"],
    },
    "body-green": {
        name: "Green",
        colors: ["068940"],
    },
    "body-gunk": {
        name: "Gunk",
        colors: ["867c1d"],
    },
    "body-hotbrown": {
        name: "Hot Brown",
        colors: ["ae3208"],
    },
    "body-magenta": {
        name: "Magenta",
        colors: ["9f21a0"],
    },
    "body-orange-yellow": {
        name: "Orange Light",
        colors: ["f98f30"],
    },
    "body-orange": {
        name: "Orange",
        colors: ["fe500c"],
    },
    "body-peachy-B": {
        name: "Peachy",
        colors: ["d26451"],
    },
    "body-peachy-a": {
        name: "Peach",
        colors: ["fd8b5b"],
    },
    "body-purple": {
        name: "Purple",
        colors: ["5a65fa"],
    },
    "body-red": {
        name: "Red",
        colors: ["d22209"],
    },
    "body-redpinkish": {
        name: "Pink",
        colors: ["e9265c"],
    },
    "body-rust": {
        name: "Rust",
        colors: ["c54e38"],
    },
    "body-slimegreen": {
        name: "Slime Green",
        colors: ["80a72d"],
    },
    "body-teal-light": {
        name: "Teal Light",
        colors: ["4bea69"],
    },
    "body-teal": {
        name: "Teal",
        colors: ["34ac80"],
    },
    "body-yellow": {
        name: "Yellow",
        colors: ["eed811"],
    },
    "accessory-1n": {
        name: "1n",
        colors: ["62616d"],
    },
    "accessory-aardvark": {
        name: "Aardvark",
        colors: ["ff638d"],
    },
    "accessory-axe": {
        name: "Axe",
        colors: ["ffffff", "8bc0c5"],
    },
    "accessory-belly-chameleon": {
        name: "Chameleon",
        colors: ["c4da53"],
    },
    "accessory-bird-flying": {
        name: "Bird Flying",
        colors: ["000000"],
    },
    "accessory-bird-side": {
        name: "Cardinal",
        colors: ["f3322c", "000000", "ffae1a"],
    },
    "accessory-bling-anchor": {
        name: "Anchor Necklace",
        colors: ["ffc110"],
    },
    "accessory-bling-anvil": {
        name: "Anvil Necklace",
        colors: ["505a5c", "343235"],
    },
    "accessory-bling-arrow": {
        name: "Arrow",
        colors: ["ffc110"],
    },
    "accessory-bling-cheese": {
        name: "Cheese Necklace",
        colors: ["ffc110", "ffef16"],
    },
    "accessory-bling-gold-ingot": {
        name: "Gold Bar Necklace",
        colors: ["ffc110", "fff671"],
    },
    "accessory-bling-love": {
        name: "Heart Necklace",
        colors: ["ffc110", "ffef16"],
    },
    "accessory-bling-mask": {
        name: "Mask Necklace",
        colors: ["ffc110"],
    },
    "accessory-bling-rings": {
        name: "Rings",
        colors: ["fff449"],
    },
    "accessory-bling-scissors": {
        name: "Scissors",
        colors: ["db8323"],
    },
    "accessory-bling-sparkles": {
        name: "Sparkles",
        colors: ["ffc110"],
    },
    "accessory-body-gradient-checkerdisco": {
        name: "Checker Disco Shirt",
        colors: ["df2c39", "000000", "5c25fb", "45faff", "f938d8", "2a86fd", "38dd56"],
    },
    "accessory-body-gradient-dawn": {
        name: "Dawn Shirt",
        colors: ["ff3a0e", "d32a09", "ae3208", "903707", "6e3206", "552e05"],
    },
    "accessory-body-gradient-dusk": {
        name: "Dusk Shirt",
        colors: ["ff3a0e", "f3322c", "e8705b", "f38b7c", "e4a499"],
    },
    "accessory-body-gradient-glacier": {
        name: "Glacier Shirt",
        colors: ["667af9", "648df9", "63a0f9", "7cc4f2", "97f2fb"],
    },
    "accessory-body-gradient-ice": {
        name: "Ice Shirt",
        colors: ["a3efd0", "87e4d9", "7cc4f2", "71bde4"],
    },
    "accessory-body-gradient-pride": {
        name: "Pride Shirt",
        colors: ["ff1a0b", "f78a18", "fff449", "068940", "2b83f6", "9f21a0"],
    },
    "accessory-body-gradient-redpink": {
        name: "Red/Pink Shirt",
        colors: ["d62149", "b9185c", "834398"],
    },
    "accessory-body-gradient-sunset": {
        name: "Sunset Shirt",
        colors: ["ffc925", "ffae1a", "f78a18", "fe500c", "d9391f", "bd2d24"],
    },
    "accessory-carrot": {
        name: "Carrot",
        colors: ["ff7216", "34ac80"],
    },
    "accessory-chain-logo": {
        name: "Chain Logo",
        colors: ["ffc110"],
    },
    "accessory-checker-RGB": {
        name: "Checker RGB",
        colors: ["f3322c", "254efb", "4bea69"],
    },
    "accessory-checker-bigwalk-blue-prime": {
        name: "Checker Blue",
        colors: ["5648ed"],
    },
    "accessory-checker-bigwalk-greylight": {
        name: "Checker Grey",
        colors: ["e5e5de"],
    },
    "accessory-checker-bigwalk-rainbow": {
        name: "Checker Rainbow",
        colors: ["9f21a0", "ffc925", "c5030e", "fe500c", "00a556"],
    },
    "accessory-checker-spaced-black": {
        name: "Checker Wide Black",
        colors: ["000000"],
    },
    "accessory-checker-spaced-white": {
        name: "Checker Wide White",
        colors: ["ffffff"],
    },
    "accessory-checker-vibrant": {
        name: "Checker Vibrant",
        colors: ["abf131", "fb4694", "2b83f6"],
    },
    "accessory-checkers-big-green": {
        name: "Checker Big Green",
        colors: ["068940"],
    },
    "accessory-checkers-big-red-cold": {
        name: "Checker Big Red",
        colors: ["e9265c"],
    },
    "accessory-checkers-black": {
        name: "Checker Small Black",
        colors: ["000000"],
    },
    "accessory-checkers-blue": {
        name: "Checker Small Blue",
        colors: ["254efb"],
    },
    "accessory-checkers-magenta-80": {
        name: "Checker Small Magenta",
        colors: ["ff638d"],
    },
    "accessory-chicken": {
        name: "Chicken",
        colors: ["ffffff", "e7a32c", "d9391f"],
    },
    "accessory-cloud": {
        name: "Cloud",
        colors: ["ffffff"],
    },
    "accessory-clover": {
        name: "Clover",
        colors: ["34ac80"],
    },
    "accessory-collar-sunset": {
        name: "Collar Sunset",
        colors: ["e9265c", "ffc110", "f78a18"],
    },
    "accessory-cow": {
        name: "Cow",
        colors: ["fff0ee", "000000", "f38b7c"],
    },
    "accessory-decay-gray-dark": {
        name: "Decay",
        colors: ["505a5c"],
    },
    "accessory-decay-pride": {
        name: "Decay Pride",
        colors: ["505a5c", "ff1a0b", "f78a18", "068940", "2b83f6", "9f21a0", "fff449"],
    },
    "accessory-dinosaur": {
        name: "Dinosaur",
        colors: ["009c59"],
    },
    "accessory-dollar-bling": {
        name: "Dollar Necklace",
        colors: ["ffc110"],
    },
    "accessory-dragon": {
        name: "Dragon",
        colors: ["068940"],
    },
    "accessory-ducky": {
        name: "Duck",
        colors: ["ffef16", "fe500c"],
    },
    "accessory-eth": {
        name: "ETH",
        colors: ["00499c", "0385eb"],
    },
    "accessory-eye": {
        name: "Eye",
        colors: ["00499c"],
    },
    "accessory-flash": {
        name: "Flash",
        colors: ["ffffff"],
    },
    "accessory-fries": {
        name: "Fries",
        colors: ["ffc110", "e11833", "b87b11"],
    },
    "accessory-glasses-logo-sun": {
        name: "Glasses",
        colors: ["000000"],
    },
    "accessory-glasses-logo": {
        name: "Nouns Glasses",
        colors: ["26b1f3"],
    },
    "accessory-glasses": {
        name: "Nouns Glasses",
        colors: ["26b1f3", "34ac80"],
    },
    "accessory-grid-simple-bege": {
        name: "Stacked Glasses",
        colors: ["fff0be"],
    },
    "accessory-heart": {
        name: "Heart",
        colors: ["d8dadf"],
    },
    "accessory-hoodiestrings-uneven": {
        name: "Hoodie Strings",
        colors: ["ffffff", "d7d3cd"],
    },
    "accessory-id": {
        name: "ID",
        colors: ["ffc110", "ffffff"],
    },
    "accessory-infinity": {
        name: "Infinity",
        colors: ["fffdf2"],
    },
    "accessory-insignia": {
        name: "Insignia",
        colors: ["eab118", "db8323"],
    },
    "accessory-leaf": {
        name: "Leaf",
        colors: ["0b5027"],
    },
    "accessory-lightbulb": {
        name: "Lightbulb",
        colors: ["f9f5cb", "cfc9b8"],
    },
    "accessory-lines-45-greens": {
        name: "Lines Green",
        colors: ["0b5027", "068940"],
    },
    "accessory-lines-45-rose": {
        name: "Lines Rose",
        colors: ["feb9d5"],
    },
    "accessory-lp": {
        name: "LP",
        colors: ["000000", "ffffff"],
    },
    "accessory-marsface": {
        name: "Mars Mars",
        colors: ["000000"],
    },
    "accessory-matrix-white": {
        name: "Matrix White",
        colors: ["fffdf2"],
    },
    "accessory-moon-block": {
        name: "Moon Block",
        colors: ["f8d689"],
    },
    "accessory-none": {
        name: "None",
        colors: [],
    },
    "accessory-oldshirt": {
        name: "Old Shirt",
        colors: ["5d6061", "76858b", "d8dadf"],
    },
    "accessory-pizza-bling": {
        name: "Pizza Necklace",
        colors: ["ffc110"],
    },
    "accessory-pocket-pencil": {
        name: "Pocket Pencil",
        colors: ["757576", "e8705b", "ffffff"],
    },
    "accessory-rain": {
        name: "Rain",
        colors: ["2b83f6"],
    },
    "accessory-rainbow-steps": {
        name: "Rainbow Steps",
        colors: ["ffc925", "fe500c", "d22209", "9f21a0"],
    },
    "accessory-rgb": {
        name: "RGB",
        colors: ["0adc4d", "ff0e0e", "1929f4"],
    },
    "accessory-robot": {
        name: "Robot",
        colors: ["000000"],
    },
    "accessory-safety-vest": {
        name: "Safety Vest",
        colors: ["f98f30", "fdf8ff"],
    },
    "accessory-scarf-clown": {
        name: "Clown Scarf",
        colors: ["4bea69", "000000"],
    },
    "accessory-secret-x": {
        name: "XCOPY X",
        colors: ["70e890"],
    },
    "accessory-shirt-black": {
        name: "Black Shirt",
        colors: ["000000", "f7913d"],
    },
    "accessory-shrimp": {
        name: "Shrimp",
        colors: ["feb9d5", "ff638d"],
    },
    "accessory-slimesplat": {
        name: "Slime Splat",
        colors: ["abf131"],
    },
    "accessory-small-bling": {
        name: "Chain Necklace",
        colors: ["ffc110"],
    },
    "accessory-snowflake": {
        name: "Snowflake",
        colors: ["ffffff"],
    },
    "accessory-stains-blood": {
        name: "Blood Stains",
        colors: ["e9265c"],
    },
    "accessory-stains-zombie": {
        name: "Zombie Stains",
        colors: ["068940"],
    },
    "accessory-stripes-and-checks": {
        name: "Stripes & Checks",
        colors: ["ff1ad2", "2b83f6", "ffef16", "4bea69", "ffffff", "000000"],
    },
    "accessory-stripes-big-red": {
        name: "Big Red Stripes",
        colors: ["f3322c"],
    },
    "accessory-stripes-blit": {
        name: "Blit Stripes",
        colors: ["1929f4", "ff82ad", "fff449"],
    },
    "accessory-stripes-blue-med": {
        name: "Blue Stripes",
        colors: ["2b83f6"],
    },
    "accessory-stripes-brown": {
        name: "Brown Stripes",
        colors: ["6e3206"],
    },
    "accessory-stripes-olive": {
        name: "Olive Stripes",
        colors: ["535a15"],
    },
    "accessory-stripes-red-cold": {
        name: "Red Stripes",
        colors: ["e9265c"],
    },
    "accessory-sunset": {
        name: "Sunset",
        colors: ["ffc110"],
    },
    "accessory-taxi-checkers": {
        name: "Taxi",
        colors: ["fdf8ff", "343235"],
    },
    "accessory-tee-yo": {
        name: "Yo Shirt",
        colors: ["ffef16"],
    },
    "accessory-text-yolo": {
        name: "YOLO",
        colors: ["abf131", "fff0be"],
    },
    "accessory-think": {
        name: "Think",
        colors: ["ffffff"],
    },
    "accessory-tie-black-on-white": {
        name: "Black Tie",
        colors: ["000000", "e5e5de"],
    },
    "accessory-tie-dye": {
        name: "Tie Dye",
        colors: ["648df9", "ffe939", "4bea69", "fa6fe2"],
    },
    "accessory-tie-purple-on-white": {
        name: "Purple Tie",
        colors: ["ab36be", "e5e5de"],
    },
    "accessory-tie-red": {
        name: "Red Tie",
        colors: ["ff1a0b"],
    },
    "accessory-txt-a2+b2": {
        name: "A2 / B2",
        colors: ["adc8cc"],
    },
    "accessory-txt-cc": {
        name: "CC",
        colors: ["604666"],
    },
    "accessory-txt-cc2": {
        name: "CC 2",
        colors: ["f20422"],
    },
    "accessory-txt-copy": {
        name: "Copy",
        colors: ["abaaa8"],
    },
    "accessory-txt-dao-black": {
        name: "DAO",
        colors: ["000000"],
    },
    "accessory-txt-doom": {
        name: "Doom",
        colors: ["000000"],
    },
    "accessory-txt-dope-text": {
        name: "Dope",
        colors: ["4b65f7"],
    },
    "accessory-txt-foo-black": {
        name: "Foo",
        colors: ["000000"],
    },
    "accessory-txt-ico": {
        name: "ICO",
        colors: ["a19c9a"],
    },
    "accessory-txt-io": {
        name: "I/O",
        colors: ["26b1f3"],
    },
    "accessory-txt-lmao": {
        name: "LMAO",
        colors: ["a3efd0"],
    },
    "accessory-txt-lol": {
        name: "LOL",
        colors: ["ffffff"],
    },
    "accessory-txt-mint": {
        name: "Mint",
        colors: ["fe500c"],
    },
    "accessory-txt-nil-grey-dark": {
        name: "Nil",
        colors: ["58565c"],
    },
    "accessory-txt-noun-f0f": {
        name: "Noun Pink",
        colors: ["ff1ad2"],
    },
    "accessory-txt-noun-green": {
        name: "Noun Green",
        colors: ["a3efd0"],
    },
    "accessory-txt-noun-multicolor": {
        name: "Noun Multicolor",
        colors: ["da42cb", "5a65fa", "068940", "fe500c"],
    },
    "accessory-txt-noun": {
        name: "Noun",
        colors: ["fa6fe2"],
    },
    "accessory-txt-pi": {
        name: "Pi",
        colors: ["62616d"],
    },
    "accessory-txt-pop": {
        name: "Pop",
        colors: ["ffef16"],
    },
    "accessory-txt-rofl": {
        name: "ROFL",
        colors: ["ffffff", "e8705b", "027c92", "ffc110"],
    },
    "accessory-txt-we": {
        name: "We",
        colors: ["26b1f3", "4bea69"],
    },
    "accessory-txt-yay": {
        name: "YAY",
        colors: ["2b83f6", "4bea69"],
    },
    "accessory-wall": {
        name: "Wall",
        colors: ["76858b"],
    },
    "accessory-wave": {
        name: "Wave",
        colors: ["26b1f3"],
    },
    "accessory-wet-money": {
        name: "Wet Money",
        colors: ["cec189", "909b0e", "63a0f9", "74580d"],
    },
    "accessory-woolweave-bicolor": {
        name: "Wool Weave",
        colors: ["027ee6", "fe500c"],
    },
    "accessory-woolweave-dirt": {
        name: "Wool Weave 2",
        colors: ["b2958d"],
    },
    "accessory-yingyang": {
        name: "Yin & Yang",
        colors: ["343235", "e5e5de"],
    },
    "body-bege": {
        name: "Body Beige",
        colors: ["c5b9a1"],
    },
    "body-gray-scale-1": {
        name: "Body White",
        colors: ["fffdf2"],
    },
    "body-gray-scale-9": {
        name: "Body Darkest Grey",
        colors: ["1f1d29"],
    },
    "body-ice-cold": {
        name: "Body Cold",
        colors: ["caeff9"],
    },
    "head-aardvark": {
        name: "Aardvark",
        colors: ["e8705b", "000000", "efad81", "7d635e"],
    },
    "head-abstract": {
        name: "Abstract",
        colors: ["0b5027", "000000", "d8dadf", "00499c", "d9391f", "eff2fa", "ffc925", "5648ed", "e11833", "fe500c"],
    },
    "head-ape": {
        name: "Ape",
        colors: ["6f597a", "d4b7b2", "d18687"],
    },
    "head-bag": {
        name: "Bag",
        colors: ["667af9", "ffc110", "5648ed", "da42cb"],
    },
    "head-bagpipe": {
        name: "Bagpipe",
        colors: ["cd916d", "068940", "5a423f", "74580d", "ae3208"],
    },
    "head-banana": {
        name: "Banana",
        colors: ["ffc110", "db8323", "000000", "909b0e", "fff0be"],
    },
    "head-bank": {
        name: "Bank",
        colors: ["62616d", "abaaa8", "4b4949"],
    },
    "head-baseball-gameball": {
        name: "Baseball",
        colors: ["fffdf2", "f3322c"],
    },
    "head-basketball": {
        name: "Basketball",
        colors: ["f78a18", "c54e38", "fdf8ff", "1f1d29"],
    },
    "head-bat": {
        name: "Bat",
        colors: ["6b3f39", "4d271b", "000000", "76858b", "ffffff"],
    },
    "head-bear": {
        name: "Bear",
        colors: ["85634f", "5a423f", "e8705b", "000000", "f9f4e6"],
    },
    "head-beer": {
        name: "Beer",
        colors: ["f8ddb0", "f9f4e6", "f78a18", "d9391f", "4d271b", "ffe939"],
    },
    "head-beet": {
        name: "Beet",
        colors: ["b92b3c", "e9265c", "34ac80", "4d271b", "6f597a", "4b4949", "ff638d"],
    },
    "head-bell": {
        name: "Bell",
        colors: ["ffc110", "ffffff", "d08b11", "e8705b"],
    },
    "head-bigfoot-yeti": {
        name: "Yeti",
        colors: ["eff2fa", "a3baed", "26b1f3", "257ced", "1929f4", "5fd4fb"],
    },
    "head-bigfoot": {
        name: "Bigfoot",
        colors: ["903707", "552e05", "db8323", "c16710", "fd8b5b"],
    },
    "head-blackhole": {
        name: "Blackhole",
        colors: ["a28ef4", "feb9d5", "e9265c", "ff638d", "3a085b", "000000"],
    },
    "head-blueberry": {
        name: "Blueberry",
        colors: ["2b83f6", "26b1f3", "67b1e3", "027c92", "1e3445", "00499c"],
    },
    "head-bomb": {
        name: "Bomb",
        colors: ["343235", "abaaa8", "cd916d", "ffd067", "ff1a0b"],
    },
    "head-bonsai": {
        name: "Bonsai",
        colors: ["068940", "4bea69", "962236", "b2958d", "1f1d29"],
    },
    "head-boombox": {
        name: "Boombox",
        colors: ["5a6b7b", "769ca9", "adc8cc", "4bea69", "1f1d29", "d8dadf", "f3322c"],
    },
    "head-boot": {
        name: "Boot",
        colors: ["7e5243", "cd916d", "ffc110", "5a423f", "a86f60"],
    },
    "head-box": {
        name: "Box",
        colors: ["7e5243", "5a423f", "8f785e", "c5b9a1"],
    },
    "head-boxingglove": {
        name: "Boxing Glove",
        colors: ["e11833", "b92b3c", "5648ed", "ffc110", "ffffff", "f38b7c", "000000"],
    },
    "head-brain": {
        name: "Brain",
        colors: ["ff82ad", "ff638d"],
    },
    "head-bubble-speech": {
        name: "Speech Bubble",
        colors: ["ffffff"],
    },
    "head-bubblegum": {
        name: "Bubble Gum",
        colors: ["ff1ad2", "ffffff", "cc0595"],
    },
    "head-burger-dollarmenu": {
        name: "Burger",
        colors: ["ffc110", "7e5243", "b87b11", "ffae1a"],
    },
    "head-cake": {
        name: "Cake",
        colors: ["ff638d", "ffffff", "e8705b", "7cc4f2", "26b1f3", "fff671", "ffc110"],
    },
    "head-calculator": {
        name: "Calculator",
        colors: ["5a423f", "cfc2ab", "f8ddb0", "d22209", "0b5027", "d56333", "42ffb0", "5648ed"],
    },
    "head-calendar": {
        name: "Calendar",
        colors: ["ffffff", "b8ced2", "000000", "e8e8e2", "f39713"],
    },
    "head-camcorder": {
        name: "Camcorder",
        colors: ["000000", "7d635e", "b2958d", "f3322c"],
    },
    "head-cannedham": {
        name: "Canned Ham",
        colors: ["74580d", "cd916d", "a3baed", "235476", "5648ed", "1f1d29", "fff0ee", "e4a499"],
    },
    "head-car": {
        name: "Car",
        colors: ["e11833", "b2a8a5", "000000", "505a5c", "63a0f9", "fff0be"],
    },
    "head-cash-register": {
        name: "Cash Register",
        colors: ["5d6061", "d8dadf", "adc8cc", "505a5c", "f9f4e6", "76858b", "2b83f6", "00499c", "fff449"],
    },
    "head-cassettetape": {
        name: "Cassette Tape",
        colors: ["1f1d29", "4b4949", "ffffff", "d6c3be", "ffc110", "62616d"],
    },
    "head-cat": {
        name: "Cat",
        colors: ["7cc4f2", "1f1d29", "caeff9", "5a6b7b", "fa6fe2"],
    },
    "head-cd": {
        name: "CD",
        colors: ["d6c3be", "ff638d", "ffc110", "c4da53", "70e890", "5a423f", "2b83f6", "ab36be", "cc0595"],
    },
    "head-chain": {
        name: "Chain",
        colors: ["6b3f39", "ffd067", "b87b11", "ffffff"],
    },
    "head-chainsaw": {
        name: "Chainsaw",
        colors: ["757576", "343235", "ffffff"],
    },
    "head-chameleon": {
        name: "Chameleon",
        colors: ["34ac80", "068940", "6b3f39", "c4da53", "000000"],
    },
    "head-chart-bars": {
        name: "Chart",
        colors: ["e9265c", "ffef16", "4bea69", "000000", "ff1ad2", "4b65f7"],
    },
    "head-cheese": {
        name: "Cheese",
        colors: ["ffc110", "fff671", "db8323", "000000"],
    },
    "head-chefhat": {
        name: "Chef Hat",
        colors: ["ffffff", "d6c3be", "b2958d", "e11833"],
    },
    "head-cherry": {
        name: "Cherry",
        colors: ["d22209", "068940", "ff1a0b", "ffffff"],
    },
    "head-chicken": {
        name: "Chicken",
        colors: ["f9f4e6", "e11833", "f98f30"],
    },
    "head-chilli": {
        name: "Chilli",
        colors: ["4bea69", "f3322c", "000000"],
    },
    "head-chipboard": {
        name: "Chipboard",
        colors: ["49b38b", "fccf25", "27a463", "99e6de", "554543", "f59b34", "375dfc", "b19e00"],
    },
    "head-chips": {
        name: "Chips",
        colors: ["5648ed", "f9f4e6", "ffffff", "fe500c", "000000", "f78a18"],
    },
    "head-chocolate": {
        name: "Chocolate",
        colors: ["a3baed", "4d271b", "1f1d29", "b92b3c", "e9265c", "7e5243", "caeff9"],
    },
    "head-cloud": {
        name: "Cloud",
        colors: ["ffffff"],
    },
    "head-clover": {
        name: "Clover",
        colors: ["4bea69", "34ac80", "068940", "0b5027"],
    },
    "head-clutch": {
        name: "Clutch",
        colors: ["eab118", "9f21a0", "da42cb", "d4a015", "ffffff", "000000"],
    },
    "head-coffeebean": {
        name: "Coffee Bean",
        colors: ["9f4b27", "903707", "000000", "6e3206"],
    },
    "head-cone": {
        name: "Cone",
        colors: ["f3322c", "f9e8dd", "bd2d24"],
    },
    "head-console-handheld": {
        name: "Handheld Console",
        colors: ["cfc2ab", "867c1d", "9d8e6e", "909b0e", "6b7212", "4243f8", "f3322c"],
    },
    "head-cookie": {
        name: "Cookie",
        colors: ["a86f60", "4d271b"],
    },
    "head-cordlessphone": {
        name: "Cordless Phone",
        colors: ["000000", "cfc2ab", "9d8e6e", "b92b3c", "f8ddb0"],
    },
    "head-cottonball": {
        name: "Cotton Ball",
        colors: ["ffffff", "e5e5de"],
    },
    "head-cow": {
        name: "Cow",
        colors: ["eff2fa", "000000", "feb9d5", "5a423f"],
    },
    "head-crab": {
        name: "Crab",
        colors: ["f98f30", "fa5e20", "000000"],
    },
    "head-crane": {
        name: "Crane",
        colors: ["f82905", "ffffff", "000000", "876f69"],
    },
    "head-croc-hat": {
        name: "Crocodile",
        colors: ["000000", "34ac80"],
    },
    "head-crown": {
        name: "Crown King",
        colors: ["257ced", "00499c", "eab118", "db8323", "6e3206", "34ac80", "42ffb0", "068940", "ff1a0b", "d22209"],
    },
    "head-crt-bsod": {
        name: "CRT",
        colors: ["c5b9a1", "9d8e6e", "fdf8ff", "1929f4", "000000"],
    },
    "head-crystalball": {
        name: "Crystal Ball",
        colors: ["f938d8", "410d66", "552d1d", "000000"],
    },
    "head-diamond-blue": {
        name: "Diamond Blue",
        colors: ["63a0f9", "254efb", "ffffff"],
    },
    "head-diamond-red": {
        name: "Diamond Red",
        colors: ["f78a18", "f71248", "ffffff"],
    },
    "head-dictionary": {
        name: "Dictionary",
        colors: ["254efb", "ffffff", "abaaa8", "ff1a0b"],
    },
    "head-dino": {
        name: "Dinosaur",
        colors: ["34ac80", "ffffff"],
    },
    "head-dna": {
        name: "DNA",
        colors: ["2b83f6", "e11833", "ffc110", "fff0ee"],
    },
    "head-dog": {
        name: "Dog",
        colors: ["4d271b", "7e5243", "f38b7c", "000000", "ffffff", "343235"],
    },
    "head-doughnut": {
        name: "Doughnut",
        colors: ["fa6fe2", "4bea69", "26b1f3", "fee3f3", "ffe939", "e9265c", "f38b7c", "1f1d29"],
    },
    "head-drill": {
        name: "Drill",
        colors: ["fe500c", "7d635e", "000000", "ffc110", "d6c3be", "b2958d"],
    },
    "head-duck": {
        name: "Duck",
        colors: ["ffffff", "ffc925", "f78a18"],
    },
    "head-ducky": {
        name: "Rubber Duck",
        colors: ["ffc110", "d08b11", "fff0ee", "000000", "fe500c"],
    },
    "head-earth": {
        name: "Earth",
        colors: ["4bea69", "2b83f6"],
    },
    "head-egg": {
        name: "Egg",
        colors: ["ffffff", "f9e8dd", "eed811"],
    },
    "head-faberge": {
        name: "Faberge Egg",
        colors: ["ffc110", "5648ed", "d08b11", "068940", "b92b3c", "000000"],
    },
    "head-factory-dark": {
        name: "Factory",
        colors: ["1f1d29", "f3322c", "abaaa8", "b2958d", "ffe939"],
    },
    "head-fan": {
        name: "Fan",
        colors: ["fff0ee", "7d635e", "76858b"],
    },
    "head-fence": {
        name: "Fence",
        colors: ["c16923", "903707", "4d271b"],
    },
    "head-film-35mm": {
        name: "35mm Film",
        colors: ["f78a18", "000000", "ffc110", "9f4b27", "f9f4e6"],
    },
    "head-film-strip": {
        name: "Film Strip",
        colors: ["2b2834", "0079fc", "027ee6"],
    },
    "head-fir": {
        name: "Fir",
        colors: ["068940", "0b5027"],
    },
    "head-firehydrant": {
        name: "Fire Hydrant",
        colors: ["d31e14", "f83001", "000000"],
    },
    "head-flamingo": {
        name: "Flamingo",
        colors: ["ff82ad", "ff638d", "b9185c", "fee3f3", "000000", "feb9d5", "1f1d29"],
    },
    "head-flower": {
        name: "Flower",
        colors: ["fb4694", "fee3f3", "cc0595", "f98f30", "ffe939", "4d271b"],
    },
    "head-fox": {
        name: "Fox",
        colors: ["f78a18", "000000", "ffffff", "ae3208"],
    },
    "head-frog": {
        name: "Frog",
        colors: ["4bea69", "70e890", "068940", "0b5027"],
    },
    "head-garlic": {
        name: "Garlic",
        colors: ["fffdf2", "fff0be", "8dd122"],
    },
    "head-gavel": {
        name: "Gavel",
        colors: ["903707", "c16710", "000000", "ffc110", "f9f5cb"],
    },
    "head-ghost-B": {
        name: "Ghost",
        colors: ["a3baed", "63a0f9"],
    },
    "head-glasses-big": {
        name: "Glasses",
        colors: ["6f597a", "ffffff"],
    },
    "head-gnome": {
        name: "Gnome",
        colors: ["ffa21e", "fffdf4", "00a556", "d08b11", "ff1a0b", "000000", "ffffff", "c16710"],
    },
    "head-goat": {
        name: "Goat",
        colors: ["9d8e6e", "7d635e", "000000", "ffffff", "343235", "a86f60"],
    },
    "head-goldcoin": {
        name: "Gold Coin",
        colors: ["ffc110", "db8323", "fff671"],
    },
    "head-goldfish": {
        name: "Goldfish",
        colors: ["8bc0c5", "5648ed", "769ca9", "fe500c", "ffae1a", "000000", "fff0ee"],
    },
    "head-grouper": {
        name: "Grouper",
        colors: ["5a423f", "74580d", "aa940c", "d18687"],
    },
    "head-hair": {
        name: "Hair",
        colors: ["c16710", "ffc110"],
    },
    "head-hardhat": {
        name: "Hard Hat",
        colors: ["fff006", "eedc00", "000000"],
    },
    "head-heart": {
        name: "Heart",
        colors: ["f3322c", "ffffff", "000000"],
    },
    "head-helicopter": {
        name: "Helicopter",
        colors: ["76858b", "ff638d", "000000", "5d6061", "cc0595", "26b1f3", "ffffff"],
    },
    "head-highheel": {
        name: "High Heel",
        colors: ["d22209", "eab118", "000000"],
    },
    "head-hockeypuck": {
        name: "Hockey Punk",
        colors: ["807f7e", "343235", "62616d", "4b4949"],
    },
    "head-horse-deepfried": {
        name: "Horse",
        colors: ["c16710", "d4a015", "d08b11", "ffe939", "000000"],
    },
    "head-hotdog": {
        name: "Hotdog",
        colors: ["cd916d", "e4a499", "b92b3c", "ffe939", "e9265c"],
    },
    "head-house": {
        name: "House",
        colors: ["cd916d", "fff0ee", "b2958d", "ae3208", "000000", "ffc110", "9cb4b8"],
    },
    "head-icepop-b": {
        name: "Icepop",
        colors: ["a38654", "4bea69", "42ffb0", "ffd067", "ffffff", "e9265c", "fd8b5b"],
    },
    "head-igloo": {
        name: "Igloo",
        colors: ["ffffff", "7cc4f2", "667af9"],
    },
    "head-island": {
        name: "Island",
        colors: ["ffc110", "ffc925", "068940", "fff671", "ae6c0a", "4bea69"],
    },
    "head-jellyfish": {
        name: "Jellyfish",
        colors: ["feb9d5", "ff82ad", "ab36be", "da42cb", "3a085b"],
    },
    "head-jupiter": {
        name: "Jupiter",
        colors: ["e8705b", "efad81", "f38b7c", "d18687", "ffae1a", "000000"],
    },
    "head-kangaroo": {
        name: "Kangaroo",
        colors: ["7d635e", "f38b7c", "d4b7b2", "000000"],
    },
    "head-ketchup": {
        name: "Ketchup",
        colors: ["f3322c", "000000", "b92b3c"],
    },
    "head-laptop": {
        name: "Laptop",
        colors: ["62616d", "fffdf2", "26b1f3", "1f1d29", "7cc4f2"],
    },
    "head-lightning-bolt": {
        name: "Lightning Bolt",
        colors: ["eed811"],
    },
    "head-lint": {
        name: "Lint",
        colors: ["a3baed", "67b1e3", "71bde4", "000000"],
    },
    "head-lips": {
        name: "Lips",
        colors: ["f3322c", "ffffff"],
    },
    "head-lipstick2": {
        name: "Lipstick",
        colors: ["fff449", "343235", "807f7e", "f3322c", "e8705b", "000000", "ffffff"],
    },
    "head-lock": {
        name: "Lock",
        colors: ["74580d", "d08b11", "ffe939", "62616d", "343235", "000000"],
    },
    "head-macaroni": {
        name: "Macaroni",
        colors: ["fff671", "ffc110", "ffef16", "b87b11"],
    },
    "head-mailbox": {
        name: "Mailbox",
        colors: ["807f7e", "b92b3c", "ffffff", "000000", "667af9"],
    },
    "head-maze": {
        name: "Maze",
        colors: ["2bb26b"],
    },
    "head-microwave": {
        name: "Microwave",
        colors: ["1f1d29", "769ca9", "caeff9", "1e3445", "8bc0c5", "2b83f6", "9cb4b8"],
    },
    "head-milk": {
        name: "Milk",
        colors: ["254efb", "9cb4b8", "eff2fa", "2b83f6", "f3322c", "b92b3c"],
    },
    "head-mirror": {
        name: "Mirror",
        colors: ["7e5243", "caeff9", "ffffff", "7cc4f2"],
    },
    "head-mixer": {
        name: "Mixer",
        colors: ["4b4949", "068940", "ffffff", "abaaa8", "0b5027", "000000", "f3322c", "abf131", "62616d", "6b7212"],
    },
    "head-moon": {
        name: "Moon",
        colors: ["f9f4e6", "cfc9b8", "000000"],
    },
    "head-moose": {
        name: "Moose",
        colors: ["cd916d", "4d271b", "a86f60", "6b3f39"],
    },
    "head-mosquito": {
        name: "Mosquito",
        colors: ["ae3208", "ff7216", "4d271b", "6e3206"],
    },
    "head-mountain-snowcap": {
        name: "Mountain",
        colors: ["34ac80", "ffffff", "5fd4fb"],
    },
    "head-mouse": {
        name: "Mouse",
        colors: ["1f1d29", "807f7e", "e5e5de", "62616d"],
    },
    "head-mug": {
        name: "Mug",
        colors: ["ffffff", "e5e5de", "adc8cc", "eff2fa", "e8705b"],
    },
    "head-mushroom": {
        name: "Mushroom",
        colors: ["e11833", "fff0ee", "f9e8dd", "000000"],
    },
    "head-mustard": {
        name: "Mustard",
        colors: ["ffc925", "ffc110", "000000", "d08b11"],
    },
    "head-nigiri": {
        name: "Nigiri",
        colors: ["f86100", "ffffff", "f89865", "dcd8d3"],
    },
    "head-noodles": {
        name: "Noodles",
        colors: [
            "fffdf2",
            "b92b3c",
            "049d43",
            "0b5027",
            "fd8b5b",
            "e9265c",
            "a86f60",
            "f8ddb0",
            "552e05",
            "ffa21e",
            "ffc925",
        ],
    },
    "head-onion": {
        name: "Onion",
        colors: ["d56333", "e8705b", "9cb4b8", "000000"],
    },
    "head-orangutan": {
        name: "Orangutan",
        colors: ["f3322c", "1f1d29", "5a423f", "000000"],
    },
    "head-orca": {
        name: "Orca",
        colors: ["343235", "fffdf2"],
    },
    "head-otter": {
        name: "Otter",
        colors: ["85634f", "d0aea9", "eff2fa", "000000"],
    },
    "head-outlet": {
        name: "Outlet",
        colors: ["fff0ee", "d6c3be", "b2958d", "000000"],
    },
    "head-owl": {
        name: "Owl",
        colors: ["f39d44", "db8323", "eeb78c", "f9f5e9", "5d3500"],
    },
    "head-oyster": {
        name: "Oyster",
        colors: ["d4b7b2", "d18687", "a86f60", "c3a199", "e11833", "fff0ee"],
    },
    "head-paintbrush": {
        name: "Paintbrush",
        colors: ["ffe939", "f3322c", "abaaa8", "9d8e6e", "58565c"],
    },
    "head-panda": {
        name: "Panda",
        colors: ["000000", "ffffff"],
    },
    "head-paperclip": {
        name: "Paperclip",
        colors: ["aaa6a4"],
    },
    "head-peanut": {
        name: "Peanut",
        colors: ["caa26a", "f8d689", "a38654"],
    },
    "head-pencil-tip": {
        name: "Pencil",
        colors: ["fff0be", "ffc110", "000000", "e8705b"],
    },
    "head-peyote": {
        name: "Peyote",
        colors: ["fde7f5", "27a463", "fdf008", "018146", "fdcef2", "f681e6"],
    },
    "head-piano": {
        name: "Piano",
        colors: ["1f1d29", "d7d3cd", "807f7e"],
    },
    "head-pickle": {
        name: "Pickle",
        colors: ["068940", "8dd122", "0b5027", "000000"],
    },
    "head-pie": {
        name: "Pie",
        colors: ["c16923", "d19a54", "769ca9", "5a6b7b", "b9185c", "e2c8c0", "1e3445", "e9265c"],
    },
    "head-piggybank": {
        name: "Piggybank",
        colors: ["da42cb", "fa6fe2", "ffffff", "f78a18", "ffae1a", "9f21a0", "ffd067"],
    },
    "head-pill": {
        name: "Pill",
        colors: ["f3322c", "ffffff", "000000", "34ac80", "068940"],
    },
    "head-pillow": {
        name: "Pillow",
        colors: ["9eb5e1", "f5fcff"],
    },
    "head-pineapple": {
        name: "Pineapple",
        colors: ["ffc110", "ffef16", "f78a18", "3f9323", "068940"],
    },
    "head-pipe": {
        name: "Pipe",
        colors: ["fd8b5b", "a86f60", "5a423f", "000000", "ffffff"],
    },
    "head-pirateship": {
        name: "Pirateship",
        colors: ["ffc110", "7e5243", "000000", "e11833", "a86f60", "fff0ee"],
    },
    "head-pizza": {
        name: "Pizza",
        colors: ["e8705b", "ffef16", "000000"],
    },
    "head-plane": {
        name: "Plane",
        colors: ["9cb4b8", "f3322c", "ffffff", "000000"],
    },
    "head-pop": {
        name: "Pop",
        colors: ["fe500c", "ffef16", "b2a8a5", "000000", "ffffff"],
    },
    "head-porkbao": {
        name: "Bao",
        colors: ["fff0be", "cd916d", "efad81"],
    },
    "head-potato": {
        name: "Potato",
        colors: ["74580d", "a38654", "000000", "ffffff"],
    },
    "head-pufferfish": {
        name: "Pufferfish",
        colors: ["ff7216", "f7913d", "ffd067", "6e3206"],
    },
    "head-pumpkin": {
        name: "Pumpkin",
        colors: ["f3322c", "bd2d24", "4d271b", "6b7212", "ffe939", "aa940c"],
    },
    "head-pyramid": {
        name: "Pyramid",
        colors: ["85634f", "6b3f39", "9d8e6e", "000000"],
    },
    "head-queencrown": {
        name: "Crown Queen",
        colors: ["eab118", "ff1a0b", "6e3206", "d22209", "257ced", "00499c", "d08b11"],
    },
    "head-rabbit": {
        name: "Rabbit",
        colors: ["abaaa8", "807f7e", "000000", "ffffff"],
    },
    "head-rainbow": {
        name: "Rainbow",
        colors: ["d22209", "fe500c", "ffc925", "068940", "00499c", "000000", "9f21a0"],
    },
    "head-rangefinder": {
        name: "Rangefinder",
        colors: ["807f7e", "1f1d29", "abaaa8", "4b4949", "ffffff"],
    },
    "head-raven": {
        name: "Raven",
        colors: ["1f1d29", "343235"],
    },
    "head-retainer": {
        name: "Retainer",
        colors: ["ff638d", "e9265c", "d6c3be"],
    },
    "head-rgb": {
        name: "RGB",
        colors: ["0adc4d", "ff0e0e", "ffef16", "ffffff", "00fcff", "ff1ad2", "1929f4"],
    },
    "head-ring": {
        name: "Ring",
        colors: ["fff449", "ffffff", "eed811", "fb4694", "fa6fe2"],
    },
    "head-road": {
        name: "Road",
        colors: ["4a5358", "fbc800", "f9f5e9"],
    },
    "head-robot": {
        name: "Robot",
        colors: ["62616d", "1f1d29", "807f7e", "d7d3cd", "fffdf2"],
    },
    "head-rock": {
        name: "Rock",
        colors: ["4b4949", "62616d"],
    },
    "head-rosebud": {
        name: "Rosebud",
        colors: ["34ac80", "e9265c", "962236", "000000"],
    },
    "head-ruler-triangular": {
        name: "Triangular Ruler",
        colors: ["cc0595", "fb4694"],
    },
    "head-saguaro": {
        name: "Saguaro",
        colors: ["909b0e", "6b7212", "000000"],
    },
    "head-sailboat": {
        name: "Sailboat",
        colors: ["ffffff", "cd916d", "a86f60", "9eb5e1"],
    },
    "head-sandwich": {
        name: "Sandwich",
        colors: [
            "4bea69",
            "cd916d",
            "d596a6",
            "e11833",
            "ffe939",
            "a86f60",
            "efad81",
            "000000",
            "3f9323",
            "f8ddb0",
            "c54e38",
        ],
    },
    "head-saturn": {
        name: "Saturn",
        colors: ["fff0ee", "667af9", "ffb913", "e11833", "fe500c"],
    },
    "head-saw": {
        name: "Saw",
        colors: ["7e5243", "ffc110", "b2a8a5", "fff0ee"],
    },
    "head-scorpion": {
        name: "Scorpion",
        colors: ["e9ba12", "767c0e", "f9f6d1", "f39713"],
    },
    "head-shark": {
        name: "Shark",
        colors: ["395ed1", "2b83f6", "7cc4f2", "ffffff"],
    },
    "head-shower": {
        name: "Shower",
        colors: ["2b83f6", "62616d", "343235", "fffdf2"],
    },
    "head-skateboard": {
        name: "Skateboard",
        colors: ["8dd122", "80a72d", "ff82ad", "ff1ad2", "b2958d", "1f1d29"],
    },
    "head-skeleton-hat": {
        name: "Skeleton",
        colors: ["2b83f6", "f9e8dd", "9d8e6e", "ffe939"],
    },
    "head-skilift": {
        name: "Skilift",
        colors: ["000000", "f8ddb0", "d32a09", "027c92", "fdf8ff"],
    },
    "head-smile": {
        name: "Smile",
        colors: ["f3322c", "ffffff"],
    },
    "head-snowglobe": {
        name: "Snowglobe",
        colors: ["a3baed", "fff0ee", "5a423f", "7e5243", "ae3208", "ffc110"],
    },
    "head-snowmobile": {
        name: "Snowmobile",
        colors: ["000000", "ffffff", "807f7e", "63a0f9", "ffc110", "e11833"],
    },
    "head-spaghetti": {
        name: "Spaghetti",
        colors: ["efad81", "ffffff", "e11833", "6b3f39", "4d271b", "049d43"],
    },
    "head-sponge": {
        name: "Sponge",
        colors: ["fff671", "c4da53", "000000"],
    },
    "head-squid": {
        name: "Squid",
        colors: ["fb4694", "ff82ad", "834398", "000000", "ffffff", "ffc5f0"],
    },
    "head-stapler": {
        name: "Stapler",
        colors: ["d22209", "f3322c", "abaaa8"],
    },
    "head-star-sparkles": {
        name: "Star",
        colors: ["d4a015", "fff671"],
    },
    "head-steak": {
        name: "Steak",
        colors: ["fff0ee", "d22209", "f38b7c"],
    },
    "head-sunset": {
        name: "Sunset",
        colors: ["ff7216", "f78a18", "ffc110", "ffef16", "ffffff"],
    },
    "head-taco-classic": {
        name: "Taco",
        colors: ["70e890", "c16710", "ae3208", "e7a32c", "fff0be", "d08b11"],
    },
    "head-taxi": {
        name: "Taxi",
        colors: ["62616d", "ffb913", "e8705b", "1f1d29", "343235", "9cb4b8", "ffffff", "34ac80", "2b83f6"],
    },
    "head-thumbsup": {
        name: "Thumbs Up",
        colors: ["ffb913", "db8323"],
    },
    "head-toaster": {
        name: "Toaster",
        colors: ["62616d", "343235", "6b3f39", "fffdf2", "caa26a", "abaaa8", "8f785e"],
    },
    "head-toiletpaper-full": {
        name: "Toilet Paper",
        colors: ["cbc1bc", "b2958d", "343235", "ffffff"],
    },
    "head-tooth": {
        name: "Tooth",
        colors: ["fff0be", "ffffff", "b2958d"],
    },
    "head-toothbrush-fresh": {
        name: "Toothbrush",
        colors: ["2b83f6", "70e890", "ffffff", "4bea69", "000000", "ffc925"],
    },
    "head-tornado": {
        name: "Tornado",
        colors: ["d4b7b2", "b2958d", "d56333", "7d635e"],
    },
    "head-trashcan": {
        name: "Trash Can",
        colors: ["807f7e", "343235", "000000", "62616d"],
    },
    "head-turing": {
        name: "Turing Machine",
        colors: ["b92b3c", "962236", "5d6061", "e2c8c0", "d18687", "ffffff"],
    },
    "head-ufo": {
        name: "UFO",
        colors: ["fb4694", "2b83f6", "ffe939", "caeff9"],
    },
    "head-undead": {
        name: "Undead",
        colors: ["6f597a", "ffc110", "909b0e", "000000", "ffae1a"],
    },
    "head-unicorn": {
        name: "Unicorn",
        colors: ["4bea69", "ff638d", "667af9", "fdf8ff", "feb9d5", "000000", "ffc110"],
    },
    "head-vent": {
        name: "Vent",
        colors: ["ffffff", "62616d", "000000", "c5b9a1"],
    },
    "head-void": {
        name: "Void",
        colors: ["000000"],
    },
    "head-volcano": {
        name: "Volcano",
        colors: ["62616d", "4b4949", "ff1a0b", "f78a18", "ffef16"],
    },
    "head-volleyball": {
        name: "Volleyball",
        colors: ["f9f5e9", "ffffff", "d4cfc0"],
    },
    "head-wall": {
        name: "Wall",
        colors: ["e8705b", "c54e38", "cd916d"],
    },
    "head-wallet": {
        name: "Wallet",
        colors: [
            "7e5243",
            "000000",
            "d6c3be",
            "b2958d",
            "fff0ee",
            "d9391f",
            "a3baed",
            "9cb4b8",
            "5a423f",
            "2b83f6",
            "ffc110",
            "a86f60",
        ],
    },
    "head-wallsafe": {
        name: "Wallsafe",
        colors: ["5a423f", "7d635e", "d6c3be", "ffffff", "000000", "f3322c"],
    },
    "head-washingmachine": {
        name: "Washing Machine",
        colors: ["000000", "cbc1bc", "abaaa8", "a19c9a", "fff0ee", "2b83f6", "58565c", "e11833"],
    },
    "head-watch": {
        name: "Watch",
        colors: ["ffc110", "fff0ee", "b87b11", "cec189", "eed811"],
    },
    "head-watermelon": {
        name: "Watermelon",
        colors: ["34ac80", "f3322c", "000000", "d22209"],
    },
    "head-wave": {
        name: "Wave",
        colors: ["9eb5e1", "254efb", "2b83f6"],
    },
    "head-weed": {
        name: "Weed",
        colors: ["068940", "0b5027"],
    },
    "head-weight": {
        name: "Weight",
        colors: ["505a5c", "5a6b7b", "1f1d29", "769ca9"],
    },
    "head-werewolf": {
        name: "Werewolf",
        colors: ["7e5243", "ffc110", "5648ed", "d4b7b2", "c3a199", "000000", "d18687", "ffffff"],
    },
    "head-whale-alive": {
        name: "Whale",
        colors: ["254efb", "26b1f3", "1f1d29"],
    },
    "head-whale": {
        name: "Beached Whale",
        colors: ["c3a199", "343235", "a86f60", "d4b7b2", "b2958d"],
    },
    "head-wine": {
        name: "Wine",
        colors: ["adc8cc", "962236", "e9265c", "ffffff"],
    },
    "head-wizardhat": {
        name: "Wizard Hat",
        colors: ["ffc925", "00499c", "395ed1", "1f1d29"],
    },
    "head-zebra": {
        name: "Zebra",
        colors: ["ffffff", "000000", "cfc9b8"],
    },
    "glasses-hip-rose": {
        name: "Hip Rose",
        colors: ["ff638d", "ffffff", "000000"],
    },
    "glasses-square-black-eyes-red": {
        name: "Black w/ Red Eyes",
        colors: ["000000", "ffffff", "ff0e0e"],
    },
    "glasses-square-black-rgb": {
        name: "Black RGB",
        colors: ["000000", "0adc4d", "ff0e0e", "1929f4"],
    },
    "glasses-square-black": {
        name: "Black",
        colors: ["000000", "ffffff"],
    },
    "glasses-square-blue-med-saturated": {
        name: "Blue",
        colors: ["2b83f6", "ffffff", "000000"],
    },
    "glasses-square-blue": {
        name: "Purple",
        colors: ["5648ed", "ffffff", "000000"],
    },
    "glasses-square-frog-green": {
        name: "Fog Green",
        colors: ["8dd122", "ffffff", "000000"],
    },
    "glasses-square-fullblack": {
        name: "Full Black",
        colors: ["000000", "ffffff"],
    },
    "glasses-square-green-blue-multi": {
        name: "Green & Blue",
        colors: ["257ced", "068940", "ffffff", "000000"],
    },
    "glasses-square-grey-light": {
        name: "Grey",
        colors: ["9cb4b8", "ffffff", "000000"],
    },
    "glasses-square-guava": {
        name: "Guava",
        colors: ["e8705b", "ffffff", "000000"],
    },
    "glasses-square-honey": {
        name: "Honey",
        colors: ["d19a54", "ffffff", "000000"],
    },
    "glasses-square-magenta": {
        name: "Magenta",
        colors: ["b9185c", "ffffff", "000000"],
    },
    "glasses-square-orange": {
        name: "Orange",
        colors: ["fe500c", "ffffff", "000000"],
    },
    "glasses-square-pink-purple-multi": {
        name: "Pink & Purple",
        colors: ["cc0595", "ff638d", "ffffff", "000000"],
    },
    "glasses-square-red": {
        name: "Red",
        colors: ["f3322c", "ffffff", "000000"],
    },
    "glasses-square-smoke": {
        name: "Smoke",
        colors: ["d7d3cd", "fdf8ff", "000000"],
    },
    "glasses-square-teal": {
        name: "Teal",
        colors: ["4bea69", "ffffff", "000000"],
    },
    "glasses-square-watermelon": {
        name: "Watermelon",
        colors: ["ec5b43", "ffffff", "000000"],
    },
    "glasses-square-yellow-orange-multi": {
        name: "Yellow & Orange",
        colors: ["f98f30", "ffc110", "ffffff", "000000"],
    },
    "glasses-square-yellow-saturated": {
        name: "Yellow",
        colors: ["ffef16", "ffffff", "000000"],
    },
    "accessory-grease": {
        name: "Grease",
        colors: ["ffd067"],
    },
    "accessory-tatewaku": {
        name: "Tatewaku",
        colors: ["fff0ee"],
    },
    "accessory-uroko": {
        name: "Uroko",
        colors: ["f9f4e6"],
    },
    "head-capybara": {
        name: "Capybara",
        colors: ["d56333", "000000", "a86f60", "ffffff"],
    },
    "head-couch": {
        name: "Couch",
        colors: ["c16923", "9f4b27", "db8323", "6e3206"],
    },
    "head-hanger": {
        name: "Hanger",
        colors: ["ffffff"],
    },
    "head-index-card": {
        name: "Index Card",
        colors: ["fffdf2", "f38b7c", "a3baed"],
    },
    "head-snowman": {
        name: "Snowman",
        colors: ["9f4b27", "ffffff", "ff7216", "7cc4f2", "f9e8dd", "f3322c", "000000"],
    },
    "head-treasurechest": {
        name: "Treasure Chest",
        colors: ["ffae1a", "000000", "6b3f39", "4d271b", "769ca9", "ffffff"],
    },
    "head-vending-machine": {
        name: "Vending Machine",
        colors: [
            "f20422",
            "000000",
            "807f7e",
            "ffa21e",
            "ffb913",
            "f3322c",
            "f82905",
            "e5e5de",
            "027ee6",
            "5fd4fb",
            "42ffb0",
        ],
    },
    "head-wine-barrel": {
        name: "Wine Barrel",
        colors: ["6b3f39", "aaa6a4", "85634f", "4d271b", "b92b3c"],
    },
    "glasses-deep-teal": {
        name: "Deep Teal",
        colors: ["027c92", "ffffff", "000000"],
    },
    "glasses-grass": {
        name: "Grass",
        colors: ["00a556", "ffffff", "000000"],
    },
    "accessory-broken-heart": {
        name: "Broken Heart",
        colors: ["d8dadf"],
    },
    "accessory-sweater": {
        name: "Sweater",
        colors: ["fffdf2"],
    },
    "head-backpack": {
        name: "Backpack",
        colors: ["552d1d", "909b0e", "535a15", "903707", "c16710"],
    },
    "head-beanie": {
        name: "Beanie",
        colors: ["f3322c", "c5030e", "000000", "ffffff"],
    },
    "head-beluga": {
        name: "Beluga",
        colors: ["fffdf2", "b8ced2", "1f1d29"],
    },
    "head-cotton-candy": {
        name: "Cotton Candy",
        colors: ["feb9d5", "ff82ad", "fff0ee"],
    },
    "head-curling-stone": {
        name: "Curling Stone",
        colors: ["5a6b7b", "9cb4b8", "254efb", "000000", "ffffff"],
    },
    "head-fax-machine": {
        name: "Fax Machine",
        colors: ["c5b9a1", "9d8e6e", "ffffff", "34ac80", "e11833", "5d6061"],
    },
    "head-satellite": {
        name: "Satellite",
        colors: ["648df9", "eff2fa", "5a65fa", "abaaa8", "f78a18", "343235", "ffb913", "757576", "ffffff", "ffc925"],
    },
    "head-tiger": {
        name: "Tiger",
        colors: ["000000", "fe500c", "fffdf4"],
    },
    "noundry-body-nyan": {
        name: "Nyan",
        colors: ["fc0002", "fe9600", "fffe00", "35ff00", "009afd", "6534ff"],
    },
    "noundry-accessory-alien": {
        name: "Alien",
        colors: ["040034"],
    },
    "noundry-accessory-body-okpc": {
        name: "Body OKPC",
        colors: ["000000", "2e82ff", "f8b73e", "00dc82", "ff44b7"],
    },
    "noundry-accessory-cat": {
        name: "Cat",
        colors: ["000000", "ffef16"],
    },
    "noundry-accessory-note": {
        name: "Note",
        colors: ["040034"],
    },
    "noundry-accessory-panda": {
        name: "Panda",
        colors: ["2b2834", "f5fcff"],
    },
    "noundry-accessory-skateboard": {
        name: "Skateboard",
        colors: ["8dd122", "80a72d", "ff82ad", "ff1ad2", "e8705b", "ffffff", "000000"],
    },
    "noundry-accessory-toga": {
        name: "Toga",
        colors: ["ffffff", "d4a015", "d32a09"],
    },
    "noundry-head-alarm-clock": {
        name: "Alarm Clock",
        colors: ["ffc110", "c5030e", "cebaa3", "adc8cc", "f5fcff", "343235"],
    },
    "noundry-head-bunny": {
        name: "Bunny",
        colors: ["e5ad00", "fdc929", "ffe38e", "ffffff"],
    },
    "noundry-head-camel": {
        name: "Camel",
        colors: ["ae6c0a", "6b3f39", "d4a015", "b87b11", "000000", "ffffff", "efad81", "a86f60"],
    },
    "noundry-head-coffee-press": {
        name: "Coffee Press",
        colors: ["adc8cc", "000000", "2b2b2b", "f8ddb0", "4d271b", "d7d3cd"],
    },
    "noundry-head-drain-hose-long": {
        name: "Drain Hose Long",
        colors: ["f9f4e6", "f9f6d1", "ffffff", "000000"],
    },
    "noundry-head-euphonium": {
        name: "Euphonium",
        colors: ["ffb913", "ffffff", "cfc9b8"],
    },
    "noundry-head-fez": {
        name: "Fez",
        colors: ["000000", "f3322c", "bd2d24"],
    },
    "noundry-head-floppy-disk": {
        name: "Floppy Disk",
        colors: ["686868", "1e1e1e", "d6d6d6", "acacac"],
    },
    "noundry-head-floss": {
        name: "Floss",
        colors: ["e5e5de", "ffffff", "caeff9", "abaaa8"],
    },
    "noundry-head-gas": {
        name: "Gas",
        colors: ["fe0c0e", "c50000", "000000", "e1c92e"],
    },
    "noundry-head-gascan": {
        name: "Gascan",
        colors: ["fec962", "3d4d5b", "172d3d", "dc3333", "b02323"],
    },
    "noundry-head-iron": {
        name: "Iron",
        colors: ["92c9f0", "f1f1f1", "c4c4c4", "797979", "3883b8", "66acdd", "000000"],
    },
    "noundry-head-jimmy": {
        name: "Jimmy",
        colors: ["4c3c3b", "ae6c0a", "d08b11", "9f4b27"],
    },
    "noundry-head-joel": {
        name: "Joel",
        colors: ["efad81", "a4664a", "c88956", "e8705b"],
    },
    "noundry-head-keyboard": {
        name: "Keyboard",
        colors: ["37375e", "20203b", "f44336", "ffffff", "00ff00"],
    },
    "noundry-head-log": {
        name: "Log",
        colors: ["caa26a", "b87b11", "d08b11", "000000", "ffffff", "0b5027"],
    },
    "noundry-head-map": {
        name: "Map",
        colors: ["f8d689", "cfc2ab", "f8ddb0", "caa26a", "343235", "c5030e"],
    },
    "noundry-head-okpc": {
        name: "OKPC",
        colors: ["838383", "4d4d4d", "cccccc"],
    },
    "noundry-head-pancake": {
        name: "Pancake",
        colors: ["c16923", "ffae1a", "ffb310", "ffc110", "fff449", "ffe939", "eed811", "eab118"],
    },
    "noundry-head-peacock": {
        name: "Peacock",
        colors: [
            "4bea69",
            "fff449",
            "38dd56",
            "4243f8",
            "254efb",
            "5648ed",
            "ffef16",
            "f8ddb0",
            "00499c",
            "b2a8a5",
            "ffffff",
            "027c92",
            "000000",
        ],
    },
    "noundry-head-penguin": {
        name: "Penguin",
        colors: ["4b4949", "ffffff", "eab118", "000000", "c16710"],
    },
    "noundry-head-sewing-machine": {
        name: "Sewing Machine",
        colors: ["f8ddb0", "f8d689", "9cb4b8", "b2958d", "b2a8a5", "b92b3c", "000000", "ffffff"],
    },
    "noundry-head-sofa": {
        name: "Sofa",
        colors: ["c16710", "9f4b27", "6e3206", "db8323", "ffa21e", "ae6c0a"],
    },
    "noundry-head-submarine": {
        name: "Submarine",
        colors: ["ffef16", "ffc925", "f98f30", "8bc0c5"],
    },
    "noundry-glasses-colour-vision": {
        name: "Colour Vision",
        colors: ["000000", "f13c9d", "df0f26", "f78121", "f9e81f", "00c009", "189bdd", "243796", "6f0a82"],
    },
    "noundry-glasses-hip-purple": {
        name: "Hip Purple",
        colors: ["ab36be", "ffffff", "000000"],
    },
    "noundry-glasses-ivory": {
        name: "Ivory",
        colors: ["f9f5cb", "ffffff", "000000"],
    },
    "noundry-glasses-kevin": {
        name: "Kevin",
        colors: ["27af03", "ffffff", "000000"],
    },
    "noundry-glasses-laserbeam": {
        name: "Laserbeam",
        colors: ["000000", "ffffff", "f8e900"],
    },
    "noundry-glasses-square-black-dizzy": {
        name: "Black Dizzy",
        colors: ["000000", "ffffff"],
    },
    "noundry-glasses-square-cross": {
        name: "Cross",
        colors: ["000000", "ffffff"],
    },
    "noundry-glasses-square-full-purple": {
        name: "Full Purple",
        colors: ["cc0595", "ab36be", "ffffff"],
    },
    "noundry-glasses-square-peace": {
        name: "Peace",
        colors: ["000000", "ffffff", "ab36be"],
    },
    "noundry-glasses-square-pink-cyclope": {
        name: "Pink Cyclope",
        colors: ["ff638d", "ffffff", "000000"],
    },
    "noundry-glasses-square-stereo": {
        name: "Stereo",
        colors: ["000000", "0c9cf3", "f20422"],
    },
    "noundry-glasses-square-unicorn": {
        name: "Unicorn",
        colors: ["4bea69", "ff638d", "667af9", "fdf8ff", "feb9d5", "000000", "ffc110", "ffef16"],
    },
    "noundry-glasses-stretch": {
        name: "Stretch",
        colors: ["737070", "b1adad", "979292", "ffffff", "000000"],
    },
    "noundry-glasses-tripple-blue": {
        name: "Tripple Blue",
        colors: ["5648ed", "ffffff", "000000"],
    },
    "noundry-glasses-tripple-teal": {
        name: "Tripple Teal",
        colors: ["4bea69", "ffffff", "000000"],
    },
    "byn-glasses-square-black": {
        name: "BYN Black",
        colors: ["000000", "ffffff"],
    },
};
