import { PanelType } from "~/constants/app-options"
import React from "react"

type Template = any
interface IAppContext {
  isMobile: boolean | undefined
  setIsMobile: React.Dispatch<React.SetStateAction<boolean | undefined>>
  templates: Template[]
  setTemplates: (templates: Template[]) => void
  uploads: any[]
  setUploads: (templates: any[]) => void
  shapes: any[]
  setShapes: (templates: any[]) => void
  activePanel: PanelType
  setActivePanel: (option: PanelType) => void
  activeSubMenu: string | null
  setActiveSubMenu: (option: string) => void
  currentTemplate: any
  setCurrentTemplate: any
}

export const AppContext = React.createContext<IAppContext>({
  isMobile: false,
  setIsMobile: () => {},
  templates: [],
  setTemplates: () => {},
  uploads: [],
  setUploads: () => {},
  shapes: [],
  setShapes: () => {},
  activePanel: PanelType.BODIES,
  setActivePanel: () => {},
  activeSubMenu: null,
  setActiveSubMenu: (value: string) => {},
  currentTemplate: {},
  setCurrentTemplate: {},
})

export function AppProvider({ children }: { children: React.ReactNode }) {
  const [isMobile, setIsMobile] = React.useState<boolean | undefined>(undefined)
  const [templates, setTemplates] = React.useState<Template[]>([])
  const [uploads, setUploads] = React.useState<any[]>([])
  const [shapes, setShapes] = React.useState<Template[]>([])
  const [activePanel, setActivePanel] = React.useState<PanelType>(
    (localStorage.getItem("activePanel_Storage") as PanelType) ?? PanelType.BODIES
  )
  const [activeSubMenu, setActiveSubMenu] = React.useState<string | null>(null)
  const [currentTemplate, setCurrentTemplate] = React.useState<any>(null)

  React.useEffect(() => {
    const activePanelTypeStorage = localStorage.getItem("activePanel_Storage") as PanelType

    if (activePanelTypeStorage !== activePanel) {
      localStorage.setItem("activePanel_Storage", activePanel)
    }
  }, [activePanel])

  const context = {
    isMobile,
    setIsMobile,
    templates,
    setTemplates,
    activePanel,
    setActivePanel,
    shapes,
    setShapes,
    activeSubMenu,
    setActiveSubMenu,
    uploads,
    setUploads,
    currentTemplate,
    setCurrentTemplate,
  }
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>
}
