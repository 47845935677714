import { createDarkTheme, darkThemePrimitives } from "baseui"

const myThemePrimitives = {
  ...darkThemePrimitives,
  mono100: "#232323",
  mono200: "#313131",
  mono300: "#121813",
  mono400: "#181a1b",
  mono500: "#1a1a1a",
  accent: "#84f8a4",
  accent100: "#f6e066",
  negative: "#ffffff",
  warning: "#f6e066",
  warning500: "#7e7154",
}

export default createDarkTheme(myThemePrimitives, {})
