import React from "react"
import { Block } from "baseui/block"
import { Button } from "baseui/button"
import { DesignType } from "~/interfaces/DesignEditor"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import { useStyletron } from "baseui"
import NounsLogo from "~/components/Icons/NounsLogo"
import LilNounsLogo from "~/components/Icons/LilNounsLogo"

export default function () {
  const { setEditorType } = useDesignEditorContext()
  const [css, theme] = useStyletron()

  return (
    <Block
      $style={{
        height: "100vh",
        width: "100vw",
        background: theme.colors.mono100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Block>
        <Block
          $style={{
            display: "flex",
            gap: "2rem",
          }}
        >
          <Block
            onClick={() => setEditorType("NOUNS")}
            $style={{
              color: theme.colors.negative,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              fontWeight: 800,
              fontFamily: "Dystopian",
              fontSize: "1rem",
              userSelect: "none",
              transition: "all 0.5s",
              cursor: "pointer",
            }}
          >
            <NounsLogo size={1} />
          </Block>
          <Block
            onClick={() => setEditorType("LILNOUNS")}
            $style={{
              color: theme.colors.negative,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              fontWeight: 800,
              paddingLeft: "2rem",
              fontFamily: "Dystopian",
              fontSize: "1rem",
              userSelect: "none",
              transition: "all 0.5s",
              cursor: "pointer",
            }}
          >
            <LilNounsLogo size={1} />
          </Block>
        </Block>
      </Block>
    </Block>
  )
}
