import React from "react"
import { styled } from "baseui"
import { Theme } from "baseui/theme"
import Common from "./Common"
import Scenes from "./Scenes"
import { useStyletron } from "baseui"

const Container = styled<"div", {}, Theme>("div", () => ({}))

export default function () {
  const [css, theme] = useStyletron()

  return (
    <Container
      $style={{
        background: theme.colors.mono100,
      }}
    >
      <Scenes />
      <Common />
    </Container>
  )
}
