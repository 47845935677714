import React from "react"
import { Canvas } from "@buildyournoun/react"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import { useStyletron } from "baseui"

export default function () {
  const { displayPlayback } = useDesignEditorContext()
  const [css, theme] = useStyletron()

  return (
    <div style={{ flex: 1, display: "flex", position: "relative" }}>
      <Canvas
        config={{
          background: theme.colors.mono100,
          controlsPosition: {
            rotation: "BOTTOM",
          },
          shadow: {},
          positions: {
            bodies: 2,
            accessories: 3,
            heads: 4,
            glasses: 5,
          },
        }}
      />
    </div>
  )
}
