import React from "react"
import { Button, SIZE } from "baseui/button"
import { HexColorPicker } from "react-colorful"
import { StatefulPopover, PLACEMENT } from "baseui/popover"
import { Plus } from "baseui/icon"
import ShuffleIcon from "~/components/Icons/ShuffleIcon"
import { Input } from "baseui/input"
import { useEditor, useFrame } from "@buildyournoun/react"
import { Modal, ROLE } from "baseui/modal"
import { Block } from "baseui/block"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import RefreshIcon from "~/components/Icons/Refresh"
import Scrollable from "~/components/Scrollable"
import { StatefulTooltip } from "baseui/tooltip"
import Scrollbar from "@layerhub-io/react-custom-scrollbar"
import SwapHorizontal from "~/components/Icons/SwapHorizontal"
import { Tabs, Tab } from "baseui/tabs"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import { useSelector } from "react-redux"
import { selectBackgrounds } from "~/store/slices/traits/selectors"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import { useStyletron } from "baseui"

interface State {
  backgroundColor: string
}

export default function () {
  const editor = useEditor()
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const [css, theme] = useStyletron()

  const backgrounds = useSelector(selectBackgrounds)

  const [state, setState] = React.useState<State>({
    backgroundColor: "#000000",
  })

  const changeBackgroundColor = (color: string) => {
    if (editor) {
      editor.frame.setBackgroundColor(color)
    }
  }

  const handleChange = (type: string, value: any) => {
    setState({ ...state, [type]: value })
    changeBackgroundColor(value)
  }

  const getRandomColor = () => {
    return "#" + backgrounds[Math.floor(Math.random() * backgrounds.length)]
  }

  const shuffleBackground = React.useCallback(() => {
    const background = editor.frame.background
    var randomColor = getRandomColor()

    if (background.fill === randomColor) {
      randomColor = getRandomColor()
    }

    handleChange("backgroundColor", randomColor)
  }, [editor])

  return (
    <Block $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          color: theme.colors.negative,
          fontFamily: "Inter",
          fontSize: "14px",
          justifyContent: "space-between",
          padding: "1.45rem",
          zIndex: 1,
          boxShadow: `0px 15px 0px -14px ${theme.colors.mono200}`,
        }}
      >
        <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
          <AngleDoubleLeft size={18} />
        </Block>
        <Block onClick={shuffleBackground} $style={{ cursor: "pointer", display: "flex" }}>
          <RefreshIcon size={18} />
          {/* <span style={{ paddingLeft: "10px" }}>Shuffle</span> */}
        </Block>
      </Block>
      <Scrollable>
        <Block $style={{ zIndex: 2 }}>
          <Block
            style={{
              padding: "1.5rem",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(5, 1fr)",
                gap: "0.5rem",
                paddingTop: "0.25rem",
              }}
            >
              <StatefulPopover
                placement={PLACEMENT.bottomLeft}
                content={
                  <div
                    style={{
                      padding: "1rem",
                      background: theme.colors.mono100,
                      border: `1px solid ${theme.colors.mono200}`,
                      width: "200px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                      textAlign: "center",
                    }}
                  >
                    <HexColorPicker onChange={(v) => handleChange("backgroundColor", v)} />
                    <Input
                      overrides={{ Input: { style: { textAlign: "center" } } }}
                      value={state.backgroundColor}
                      onChange={(e) => handleChange("backgroundColor", (e.target as any).value)}
                      placeholder="#000000"
                      clearOnEscape
                    />
                  </div>
                }
                accessibilityType={"tooltip"}
              >
                <div
                  style={{
                    background:
                      "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%)",
                    border: `1px solid ${theme.colors.mono200}`,
                    height: "34px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      height: "34px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#ffffff",
                      fontSize: "1.3rem",
                    }}
                  >
                    <Plus size={24} />
                  </div>
                </div>
              </StatefulPopover>

              <div
                onClick={() => handleChange("backgroundColor", "transparent")}
                style={{
                  background: `
                    linear-gradient(45deg, #fff 25%, transparent 25%), 
                    linear-gradient(-45deg, #fff 25%, transparent 25%), 
                    linear-gradient(45deg, transparent 75%, #fff 75%), 
                    linear-gradient(-45deg, transparent 75%, #fff 75%)`,
                  backgroundSize: "10px 10px", // Adjusted size
                  backgroundPosition: "0 0, 0 5px, 5px -5px, -5px 0px",
                  border:
                    editor && editor.frame.background && editor.frame.background.fill === "#00000000"
                      ? `1px solid ${theme.colors.accent}`
                      : `1px solid ${theme.colors.mono200}`,
                  height: "34px",
                  cursor: "pointer",
                }}
              ></div>
              {backgrounds.map((background) => (
                <div
                  onClick={() => handleChange("backgroundColor", `#${background}`)}
                  key={background}
                  style={{
                    background: `#${background}`,
                    border:
                      editor && editor.frame.background && editor.frame.background.fill === `#${background}`
                        ? `1px solid ${theme.colors.accent}`
                        : `1px solid ${theme.colors.mono200}`,
                    height: "34px",
                    cursor: "pointer",
                  }}
                ></div>
              ))}
            </div>
          </Block>
        </Block>
      </Scrollable>
    </Block>
  )
}
