import Path from "./Path"
import Image from "./Image"
import Default from "./Default"
import Vector from "./Vector"

export default {
  Default: Default,
  StaticPath: Path,
  StaticImage: Image,
  StaticVector: Vector,
}
