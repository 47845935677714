function BackgroundIcon({ size, fill = "currentColor" }: { size: number; fill?: string }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3H3V4V5V6V7V8V9V10V11V12V13V14V15V16V17V18V19V20V21H4H5H6H7H8H9H10H11H12H13H14H15H16H17H18H19H20H21V20V19V18V17V16V15V14V13V12V11V10V9V8V7V6V5V4V3H20H19H18H17H16H15H14H13H12H11H10H9H8H7H6H5H4ZM5 7V8V9H6H7H8H9H10H11H12H13H14H15H16H17H18H19V8V7V6V5H18H17H16H15H14H13H12H11H10H9H8H7H6H5V6V7ZM5 11V12V13V14V15V16V17V18V19H6H7H8H9V18V17V16V15V14V13V12V11H8H7H6H5ZM12 19H11V18V17V16V15V14V13V12V11H12H13H14H15H16H17H18H19V12V13V14V15V16V17V18V19H18H17H16H15H14H13H12Z"
        fill={fill}
      />
    </svg>
  )
}

export default BackgroundIcon
