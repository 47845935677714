import { Trait } from "~/interfaces/common"
import { createReducer } from "@reduxjs/toolkit"
import { setBackgrounds, setBodyTraits, setAccessoryTraits, setHeadTraits, selectGlassesTraits } from "./actions"

export interface TraitState {
  backgrounds: string[]
  bodies: Trait[]
  accessories: Trait[]
  heads: Trait[]
  glasses: Trait[]
}

const initialState: TraitState = {
  backgrounds: [],
  bodies: [],
  accessories: [],
  heads: [],
  glasses: [],
}

export const traitsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setBackgrounds, (state, { payload }) => {
    state.backgrounds = payload
  })

  builder.addCase(setBodyTraits, (state, { payload }) => {
    state.bodies = payload
  })

  builder.addCase(setAccessoryTraits, (state, { payload }) => {
    state.accessories = payload
  })

  builder.addCase(setHeadTraits, (state, { payload }) => {
    state.heads = payload
  })

  builder.addCase(selectGlassesTraits, (state, { payload }) => {
    state.glasses = payload
  })
})
