import { Block } from "baseui/block"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import Scrollable from "~/components/Scrollable"
import { useStyletron } from "baseui"
import { Accordion, Panel } from "baseui/accordion"

export default function () {
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const [css, theme] = useStyletron()

  return (
    <Block $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          color: theme.colors.negative,
          justifyContent: "space-between",
          padding: "1.45rem",
          zIndex: 1,
          boxShadow: `0px 15px 0px -14px ${theme.colors.mono200}`,
        }}
      >
        <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
          <AngleDoubleLeft size={18} />
        </Block>
        <Block></Block>
      </Block>
      <Scrollable>
        <Block $style={{ zIndex: 2 }}>
          <Block
            style={{
              padding: "1.5rem",
              fontFamily: "Dystopian",
              fontWeight: 800,
              fontSize: "28px",
              color: theme.colors.negative,
            }}
          >
            FAQ
          </Block>
        </Block>
        <Block $style={{ zIndex: 2 }}>
          <Accordion
            accordion
            overrides={{
              Header: {
                style: {
                  fontFamily: "Dystopian",
                  fontSize: "16px",
                  backgroundColor: theme.colors.mono100,
                  color: theme.colors.negative,
                  border: "none",
                },
              },
              Content: {
                style: {
                  fontSize: "12px",
                  fontFamily: "Fira Code, monospace",
                  backgroundColor: theme.colors.mono100,
                  color: theme.colors.negative,
                  border: "none",
                },
              },
              PanelContainer: {
                style: {
                  border: "none",
                },
              },
            }}
          >
            <Panel title="Minting price">
              Each trait costs 0.0069 ETH, unless the artist of the trait has requested an increased price. <br />
              <br /> There is a minimum requirement of <strong>4</strong> traits to be selected. <br />
              <br />
              Solid backgrounds are not considered a priceable trait, therefore solid backgrounds are{" "}
              <strong>FREE</strong>.
            </Panel>
            <Panel title="Artist Comission">
              <strong>10%</strong> of each mint price is dedicated to trait artists.
              <br />
              <br />
              Each arist is to receive <strong>X%</strong> of mint price directly on mint, which is a <strong>4</strong>{" "}
              way split.
            </Panel>
            <Panel title="Project Funding">
              <strong>15%</strong> of each mint price, directly on mint, goes to the project treasury.
            </Panel>
            <Panel title="Funding Use Case">
              <ul>
                <li>Hiring Artists</li>
                <li>Acquiring Nounish Art</li>
                <li>Extension Embedding</li>
                <li>Project Development</li>
                <li>Giveaways</li>
              </ul>
            </Panel>
            <Panel title="Royalties">
              The minter can select the royalty percentage between the Minimum & Maximum percentages.
              <br />
              <br />
              <ul>
                <li>
                  <strong>Min: </strong> 2.5%
                </li>
                <li>
                  <strong>Max: </strong> 10%
                </li>
              </ul>
            </Panel>
            <Panel title="Art is CC0?">
              Obviously... it's Nounish! But for clarity, <strong>yes.</strong>
            </Panel>
          </Accordion>
        </Block>
      </Scrollable>
    </Block>
  )
}
