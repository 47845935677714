"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildSVG = exports.getRectLength = exports.decodeImage = void 0;
const image_data_1 = require("./image-data");
const decodeImage = (image) => {
    var _a, _b;
    const data = image.replace(/^0x/, "");
    const paletteIndex = parseInt(data.substring(0, 2), 16);
    const bounds = {
        top: parseInt(data.substring(2, 4), 16),
        right: parseInt(data.substring(4, 6), 16),
        bottom: parseInt(data.substring(6, 8), 16),
        left: parseInt(data.substring(8, 10), 16),
    };
    const rects = data.substring(10);
    return {
        paletteIndex,
        bounds,
        rects: (_b = (_a = rects === null || rects === void 0 ? void 0 : rects.match(/.{1,4}/g)) === null || _a === void 0 ? void 0 : _a.map((rect) => [parseInt(rect.substring(0, 2), 16), parseInt(rect.substring(2, 4), 16)])) !== null && _b !== void 0 ? _b : [],
    };
};
exports.decodeImage = decodeImage;
const getRectLength = (currentX, drawLength, rightBound) => {
    const remainingPixelsInLine = rightBound - currentX;
    return drawLength <= remainingPixelsInLine ? drawLength : remainingPixelsInLine;
};
exports.getRectLength = getRectLength;
const buildSVG = (parts, paletteColors, isCustom = false, bgColor = "transparent", width = 1280, height = 1280) => {
    var _a;
    const svgWithoutEndTag = parts.reduce((result, part) => {
        const svgRects = [];
        const { bounds, rects } = (0, exports.decodeImage)(part.data);
        let currentX = bounds.left;
        let currentY = bounds.top;
        const palette = image_data_1.Images[part.filename].colors;
        const customPalette = [...Array(palette.length).keys()].map(() => Math.floor(Math.random() * 16777215).toString(16));
        rects.forEach((draw) => {
            let [drawLength, colorIndex] = draw;
            let hexColor = paletteColors[colorIndex];
            if (isCustom) {
                hexColor = customPalette[palette.indexOf(hexColor)];
            }
            let length = (0, exports.getRectLength)(currentX, drawLength, bounds.right);
            while (length > 0) {
                if (colorIndex !== 0) {
                    svgRects.push(`<rect width="${length * 10}" height="10" x="${currentX * 10}" y="${currentY * 10}" fill="#${hexColor}" />`);
                }
                currentX += length;
                if (currentX === bounds.right) {
                    currentX = bounds.left;
                    currentY++;
                }
                drawLength -= length;
                length = (0, exports.getRectLength)(currentX, drawLength, bounds.right);
            }
        });
        result += svgRects.join("");
        return result;
    }, `<svg width="${width}" height="${height}" viewBox="0 0 320 320" xmlns="http://www.w3.org/2000/svg" shape-rendering="crispEdges">${(_a = bgColor !== "transparent") !== null && _a !== void 0 ? _a : `<rect width="100%" height="100%" fill="#${bgColor}" />`}`);
    return `${svgWithoutEndTag}</svg>`;
};
exports.buildSVG = buildSVG;
