import { combineReducers } from "@reduxjs/toolkit"
import { designEditorReducer } from "./slices/design-editor/reducer"
import { traitsReducer } from "./slices/traits/reducer"

const rootReducer = combineReducers({
  designEditor: designEditorReducer,
  traits: traitsReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
