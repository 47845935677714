import React from "react"
import { Block } from "baseui/block"
import { useEditor } from "@buildyournoun/react"

export async function updateVisibleLayers(editor: any, template: any): Promise<void> {
  const layerVisibilities = await Promise.all(
    template.layers.slice(1).map(async (layer: any) => ({
      id: layer.id,
      isVisible: await editor.objects.findOneById(layer.id).visible,
    }))
  )

  const visibleLayerIds = layerVisibilities.filter((layer) => layer.isVisible).map((layer) => layer.id)

  template.layers = template.layers.filter(
    (layer: any, index: number) => index === 0 || visibleLayerIds.includes(layer.id)
  )
}

export default function () {
  const editor = useEditor()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [state, setState] = React.useState({
    image: "",
  })

  const makePreview = React.useCallback(async () => {
    if (editor) {
      var template = editor.scene.exportToJSON()

      await updateVisibleLayers(editor, template)

      const image = (await editor.renderer.render(template)) as string
      setState({ image })
      setLoading(false)
    }
  }, [editor])

  React.useEffect(() => {
    makePreview()
  }, [editor])

  return (
    <Block
      $style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        padding: "5rem",
      }}
    >
      {!loading && <img width={"auto"} height={"100%"} src={state.image} />}
    </Block>
  )
}
