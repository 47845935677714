import { nanoid } from "nanoid"
import { IFrame, IScene } from "@buildyournoun/types"

export const defaultTemplate: IScene = {
  id: nanoid(),
  frame: {
    width: 1280,
    height: 1280,
  },
  layers: [
    {
      id: "background",
      name: "Initial Frame",
      left: 0,
      top: 0,
      width: 1280,
      height: 1280,
      type: "Background",
      metadata: {},
    },
  ],
  metadata: {},
}

export const getDefaultTemplate = ({
  width,
  height,
  background,
}: {
  width: number
  height: number
  background: string
}) => {
  return {
    id: nanoid(),
    frame: {
      width,
      height,
    },
    layers: [
      {
        id: "background",
        name: "Initial Frame",
        left: 0,
        top: 0,
        width,
        height,
        type: "Background",
        fill: background,
        metadata: {},
      },
    ],
    metadata: {},
  }
}
