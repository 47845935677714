import React from "react"
import { useEditor, useObjects } from "@buildyournoun/react"
import { Block } from "baseui/block"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import Scrollable from "~/components/Scrollable"
import { ILayer } from "@buildyournoun/types"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import { useStyletron } from "baseui"

export default function () {
  const editor = useEditor()
  const objects = useObjects() as ILayer[]
  const [traits, setTraits] = React.useState<any[]>([])
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const [css, theme] = useStyletron()

  React.useEffect(() => {
    if (objects) {
      setTraits(objects)
    }
  }, [objects])

  React.useEffect(() => {
    let watcher = async () => {
      if (objects) {
        setTraits([...objects])
      }
    }
    if (editor) {
      editor.on("history:changed", watcher)
    }
    return () => {
      if (editor) {
        editor.off("history:changed", watcher)
      }
    }
  }, [editor, objects])

  React.useEffect(() => {
    if (objects.length === 0) {
      setIsSidebarOpen(false)
    }
  }, [editor, objects])

  return (
    <Block $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          color: theme.colors.negative,
          justifyContent: "space-between",
          padding: "1.45rem",
          zIndex: 1,
          boxShadow: `0px 15px 0px -14px ${theme.colors.mono200}`,
        }}
      >
        <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
          <AngleDoubleLeft size={18} />
        </Block>
        <Block></Block>
      </Block>
      <Scrollable>
        <Block $style={{ zIndex: 2 }}>
          <Block
            style={{
              display: "grid",
              gap: "8px",
              padding: "1.5rem",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            {traits.map((trait) => (
              <TraitItem trait={trait} key={trait.id} />
            ))}
          </Block>
        </Block>
      </Scrollable>
    </Block>
  )
}

function TraitItem({ trait }: { trait: any }) {
  const editor = useEditor()
  const [css, theme] = useStyletron()

  return (
    <div
      onClick={() => {
        if (trait.visible) {
          editor.objects.update({ visible: false }, trait.id)
        } else {
          editor.objects.update({ visible: true }, trait.id)
          editor.objects.select(trait.id)
        }
      }}
      className={css({
        position: "relative",
        border: trait.visible ? `1px solid ${theme.colors.accent}` : `1px solid ${theme.colors.mono400}`,
        cursor: "pointer",
        overflow: "hidden",
        "::before:hover": {
          opacity: 1,
        },
      })}
    >
      <div
        className={css({
          background: theme.colors.accent100,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
          transition: "opacity 0.3s ease-in-out",
          height: "100%",
          width: "100%",
          ":hover": {
            opacity: 0.1,
          },
        })}
      ></div>
      <img
        src={trait.src}
        className={css({
          width: "100%",
          height: "100%",
          objectFit: "contain",
          pointerEvents: "none",
          verticalAlign: "middle",
        })}
      />
    </div>
  )
}
