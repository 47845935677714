"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildIsomer = void 0;
const svg_builder_1 = require("./svg-builder");
const utils_1 = require("./utils");
const DEFAULT_RGBA = {
    r: 255,
    g: 255,
    b: 255,
    a: 0,
};
const DEFAULT_PART = 0;
const DEFAULT_ISLIGHT = false;
const getBaseIsomer = (length) => {
    return Array.from({ length }, (_, row) => Array.from({ length }, (_, col) => ({
        positions: {
            x: length - 1 - col,
            y: row,
        },
        rgba: DEFAULT_RGBA,
        part: DEFAULT_PART,
        isLight: DEFAULT_ISLIGHT,
        filename: "Zero",
    }))).flat();
};
const buildIsomer = (parts, paletteColors, width) => {
    const coordinates = getBaseIsomer(width);
    parts.forEach((part, number) => {
        const { bounds, rects } = (0, svg_builder_1.decodeImage)(part.data);
        let currentX = bounds.left;
        let currentY = bounds.top;
        rects.forEach((draw) => {
            let drawLength = draw[0];
            const colorIndex = draw[1];
            const hexColor = paletteColors[colorIndex];
            const rgba = (0, utils_1.hexToRgb)(hexColor);
            let length = (0, svg_builder_1.getRectLength)(currentX, drawLength, bounds.right);
            while (length > 0) {
                ;
                [...Array(length).keys()].forEach((i) => {
                    if (colorIndex !== 0) {
                        const index = coordinates.findIndex((rect) => rect.positions.x === currentX + i && rect.positions.y === currentY);
                        if (index !== -1) {
                            coordinates[index] = Object.assign(Object.assign({}, coordinates[index]), { rgba: {
                                    r: rgba[0],
                                    g: rgba[1],
                                    b: rgba[2],
                                    a: rgba[3],
                                }, part: number, filename: part.filename });
                        }
                    }
                });
                currentX += length;
                if (currentX === bounds.right) {
                    currentX = bounds.left;
                    currentY++;
                }
                drawLength -= length;
                length = (0, svg_builder_1.getRectLength)(currentX, drawLength, bounds.right);
            }
        });
    });
    return coordinates.filter((coordinate) => coordinate.rgba.a !== 0);
};
exports.buildIsomer = buildIsomer;
