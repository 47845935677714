import React from "react"
import { Block } from "baseui/block"
import { HexColorPicker } from "react-colorful"
import Icons from "~/components/Icons"
import { Button, KIND } from "baseui/button"
import { Input } from "baseui/input"
import Common from "./Common"
import { useEditor, useActiveObject } from "@buildyournoun/react"
import { groupBy } from "lodash"
import { PLACEMENT, StatefulPopover } from "baseui/popover"
import { StatefulTooltip } from "baseui/tooltip"
import { useStyletron } from "baseui"

export default function () {
  const [state, setState] = React.useState<any>({ colors: [], colorMap: {} })
  const vectorPaths = React.useRef<any>({})
  const editor = useEditor()
  const activeObject = useActiveObject() as any
  const [css, theme] = useStyletron()

  React.useEffect(() => {
    if (activeObject && activeObject.type === "StaticVector") {
      const objects = activeObject._objects[0]._objects
      const objectColors = groupBy(objects, "fill")
      vectorPaths.current = objectColors
      setState({
        ...state,
        colors: Object.keys(objectColors),
        colorMap: activeObject.colorMap,
      })
    }
  }, [activeObject])

  const changeBackgroundColor = (prev: string, next: string) => {
    const objectRef = activeObject
    objectRef.updateLayerColor(prev, next)
    editor.objects.update({}, objectRef.id)

    setState({
      ...state,
      colorMap: {
        ...state.colorMap,
        [prev]: next,
      },
    })
  }

  const shuffleColors = () => {
    const colors: { [key: string]: string } = {}
    const objectRef = activeObject

    Object.keys(state.colorMap).map((c) => {
      const randomColor = "#" + ((Math.random() * 0xffffff) << 0).toString(16)
      colors[c] = randomColor

      objectRef.updateLayerColor(c, randomColor)
    })

    setState({
      ...state,
      colorMap: colors,
    })

    editor.objects.update({}, objectRef.id)
  }

  return (
    <Block
      $style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        padding: "0 30px 0 12px",
        justifyContent: "space-between",
        background: `${theme.colors.mono400}`,
        borderBottom: `1px solid ${theme.colors.mono200}`,
      }}
    >
      <Block>
        <Block $style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <Block $style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            {Object.keys(state.colorMap).map((c, index) => {
              return (
                <StatefulPopover
                  key={index}
                  placement={PLACEMENT.bottomLeft}
                  content={
                    <div
                      style={{
                        padding: "1rem",
                        background: theme.colors.mono100,
                        border: `1px solid ${theme.colors.mono200}`,
                        width: "200px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        textAlign: "center",
                      }}
                    >
                      <HexColorPicker
                        color={state.colorMap[c]}
                        onChange={(color) => {
                          changeBackgroundColor(c, color)
                        }}
                      />
                      <Input
                        overrides={{ Input: { style: { textAlign: "center" } } }}
                        value={state.colorMap[c]}
                        onChange={(color) => changeBackgroundColor(c, (color.target as any).value)}
                        placeholder="#000000"
                        clearOnEscape
                      />
                    </div>
                  }
                  accessibilityType={"tooltip"}
                >
                  <div>
                    <div
                      style={{
                        height: "24px",
                        width: "24px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        backgroundColor: state.colorMap[c],
                        border: `1px solid ${theme.colors.mono200}`,
                      }}
                    ></div>
                  </div>
                </StatefulPopover>
              )
            })}
          </Block>
          <Block>
            {/* <StatefulTooltip
              placement={PLACEMENT.right}
              showArrow={true}
              accessibilityType={"tooltip"}
              content="Shuffle"
              overrides={{
                Inner: {
                  style: {
                    color: theme.colors.white,
                  },
                },
              }}
            > */}
            <Button
              size="compact"
              onClick={shuffleColors}
              kind={KIND.tertiary}
              overrides={{
                StartEnhancer: {
                  style: {
                    marginRight: "4px",
                  },
                },
              }}
            >
              <Icons.Refresh size={16} />
              {/* <span style={{ paddingLeft: "10px" }}>Shuffle</span> */}
            </Button>
            {/* </StatefulTooltip> */}
          </Block>
        </Block>
      </Block>
      <Common />
    </Block>
  )
}
