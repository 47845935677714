import PanelItem from "./PanelItem"
import PanelsList from "./PanelsList"

function Panels() {
  return (
    <>
      <PanelsList />
      <PanelItem />
    </>
  )
}

export default Panels
