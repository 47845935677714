"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRandomNounSeed = exports.getNounData = void 0;
const getNounData = (seed, images, bgcolors) => {
    return {
        parts: [
            images.bodies[seed.body],
            images.accessories[seed.accessory],
            images.heads[seed.head],
            images.glasses[seed.glasses],
        ],
        background: bgcolors[seed.background],
    };
};
exports.getNounData = getNounData;
const getRandomNounSeed = (images, bgcolors) => {
    return {
        background: Math.floor(Math.random() * bgcolors.length),
        body: Math.floor(Math.random() * images.bodies.length),
        accessory: Math.floor(Math.random() * images.accessories.length),
        head: Math.floor(Math.random() * images.heads.length),
        glasses: Math.floor(Math.random() * images.glasses.length),
    };
};
exports.getRandomNounSeed = getRandomNounSeed;
