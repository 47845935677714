import axios, { AxiosInstance } from "axios"
import { Decrypt } from "@buildyournoun/encryption"
import { Trait } from "~/interfaces/common"

class ApiService {
  base: AxiosInstance

  constructor() {
    this.base = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    })
  }

  getBackgrounds(origin: string = "nouns"): Promise<string[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get("/traits/backgrounds", {
          params: {
            origin,
          },
        })
        const decrypt = JSON.parse(Decrypt(data.data))

        resolve(decrypt)
      } catch (err) {
        reject(err)
      }
    })
  }

  getTraits(trait: string, origin: string = "nouns"): Promise<Trait[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await this.base.get(`/traits/${trait}`, {
          params: {
            origin,
          },
        })
        const decrypt = JSON.parse(Decrypt(data.data))

        resolve(decrypt)
      } catch (err) {
        reject(err)
      }
    })
  }
}

export default new ApiService()
