import React from "react"
import { useStyletron } from "baseui"
import Add from "~/components/Icons/Add"
import useDesignEditorPages from "~/hooks/useDesignEditorScenes"
import { DesignEditorContext } from "~/contexts/DesignEditor"
import { nanoid } from "nanoid"
import { getDefaultTemplate } from "~/constants/design-editor"
import { useEditor } from "@buildyournoun/react"
import { IScene } from "@buildyournoun/types"
import { Block } from "baseui/block"
import { useSelector } from "react-redux"
import { selectBackgrounds } from "~/store/slices/traits/selectors"
import Scrollbars from "@layerhub-io/react-custom-scrollbar"

export default function () {
  const scenes = useDesignEditorPages()
  const { setScenes, setCurrentScene, currentScene, setCurrentDesign, currentDesign } =
    React.useContext(DesignEditorContext)
  const editor = useEditor()
  const [css, theme] = useStyletron()
  const [currentPreview, setCurrentPreview] = React.useState<string>("")
  const backgrounds = useSelector(selectBackgrounds)

  React.useEffect(() => {
    if (editor && scenes && currentScene) {
      const isCurrentSceneLoaded = scenes.find((s) => s.id === currentScene?.id)
      if (!isCurrentSceneLoaded) {
        setCurrentScene(scenes[0])
      }
    }
  }, [editor, scenes, currentScene])

  React.useEffect(() => {
    let watcher = async () => {
      const updatedTemplate = editor.scene.exportToJSON()
      const updatedPreview = (await editor.renderer.render(updatedTemplate)) as string
      setCurrentPreview(updatedPreview)
    }
    if (editor) {
      editor.on("history:changed", watcher)
    }
    return () => {
      if (editor) {
        editor.off("history:changed", watcher)
      }
    }
  }, [editor])

  React.useEffect(() => {
    if (editor) {
      if (currentScene) {
        updateCurrentScene(currentScene)
      } else {
        const defaultTemplate = getDefaultTemplate({
          width: 1280,
          height: 1280,
          background: `#${backgrounds[Math.floor(Math.random() * backgrounds.length)]}`,
        })
        setCurrentDesign({
          id: nanoid(),
          frame: defaultTemplate.frame,
          metadata: {},
          name: "Untitled Design",
          preview: "",
          scenes: [],
          type: "NOUN",
        })
        editor.scene
          .importFromJSON(defaultTemplate)
          .then(() => {
            const initialDesign = editor.scene.exportToJSON() as any
            editor.renderer.render(initialDesign).then((data) => {
              setCurrentScene({ ...initialDesign, preview: data })
              setScenes([{ ...initialDesign, preview: data }])
            })
          })
          .catch(console.log)
      }
    }
  }, [editor, currentScene])

  const updateCurrentScene = React.useCallback(
    async (design: IScene) => {
      await editor.scene.importFromJSON(design)
      const updatedPreview = (await editor.renderer.render(design)) as string
      setCurrentPreview(updatedPreview)
    },
    [editor, currentScene]
  )

  const addScene = React.useCallback(async () => {
    if (scenes.length >= 5) return

    setCurrentPreview("")
    const updatedTemplate = editor.scene.exportToJSON()
    const updatedPreview = await editor.renderer.render(updatedTemplate)

    const updatedPages = scenes.map((p) => {
      if (p.id === updatedTemplate.id) {
        return { ...updatedTemplate, preview: updatedPreview }
      }
      return p
    })

    const defaultTemplate = getDefaultTemplate({
      width: 1280,
      height: 1280,
      background: `#${backgrounds[Math.floor(Math.random() * backgrounds.length)]}`,
    })

    const newPreview = await editor.renderer.render(defaultTemplate)
    const newPage = { ...defaultTemplate, id: nanoid(), preview: newPreview } as any
    const newPages = [...updatedPages, newPage] as any[]
    setScenes(newPages)
    setCurrentScene(newPage)
  }, [scenes, currentDesign])

  const changePage = React.useCallback(
    async (page: any) => {
      setCurrentPreview("")
      if (editor) {
        const updatedTemplate = editor.scene.exportToJSON()
        const updatedPreview = await editor.renderer.render(updatedTemplate)

        const updatedPages = scenes.map((p) => {
          if (p.id === updatedTemplate.id) {
            return { ...updatedTemplate, preview: updatedPreview }
          }
          return p
        }) as any[]

        setScenes(updatedPages)
        setCurrentScene(page)
      }
    },
    [editor, scenes, currentScene]
  )

  return <></>

  return (
    <Block
      $style={{
        padding: "0.25rem 0.75rem",
        background: theme.colors.mono100,
        borderTop: `1px solid ${theme.colors.mono200}`,
      }}
    >
      <Block $style={{ display: "flex", alignItems: "center" }}>
        {scenes.map((page, index) => (
          <div
            style={{
              padding: "1rem 0.5rem",
            }}
            key={index}
          >
            <div
              onClick={() => changePage(page)}
              className={css({
                cursor: "pointer",
                position: "relative",
                border: page.id === currentScene?.id ? `2px solid ${theme.colors.accent}` : "",
              })}
            >
              <img
                style={{ maxWidth: "90px", maxHeight: "80px", display: "flex" }}
                src={currentPreview && page.id === currentScene?.id ? currentPreview : page.preview}
              />
              <div
                className={css({
                  position: "absolute",
                  bottom: "4px",
                  right: "4px",
                  color: "#fff",
                  fontSize: "10px",
                  borderRadius: "2px",
                  height: "16px",
                  width: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                })}
              ></div>
            </div>
          </div>
        ))}
        <div
          style={{
            background: theme.colors.mono100,
          }}
        >
          <div
            onClick={addScene}
            className={css({
              width: "100px",
              height: "100px",
              color: theme.colors.negative,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            })}
          >
            <Add size={20} />
          </div>
        </div>
      </Block>
    </Block>
  )
}
