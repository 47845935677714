import { Block } from "baseui/block"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import Scrollable from "~/components/Scrollable"
import { useStyletron } from "baseui"
import { Accordion, Panel } from "baseui/accordion"

export default function () {
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const [css, theme] = useStyletron()

  return (
    <Block $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          color: theme.colors.negative,
          justifyContent: "space-between",
          padding: "1.45rem",
          zIndex: 1,
          boxShadow: `0px 15px 0px -14px ${theme.colors.mono200}`,
        }}
      >
        <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
          <AngleDoubleLeft size={18} />
        </Block>
        <Block></Block>
      </Block>
      <Scrollable>
        <Block $style={{ zIndex: 2 }}>
          <Block
            style={{
              padding: "1.5rem",
              fontFamily: "Dystopian",
              fontWeight: 800,
              fontSize: "28px",
              color: theme.colors.negative,
            }}
          >
            Roadmap
          </Block>
        </Block>
        <Block $style={{ zIndex: 2 }}>
          <Accordion
            accordion
            overrides={{
              Header: {
                style: {
                  fontFamily: "Dystopian",
                  fontSize: "16px",
                  backgroundColor: theme.colors.mono100,
                  color: theme.colors.negative,
                  border: "none",
                },
              },
              Content: {
                style: {
                  fontSize: "12px",
                  fontFamily: "Fira Code, monospace",
                  backgroundColor: theme.colors.mono100,
                  color: theme.colors.negative,
                  border: "none",
                },
              },
              PanelContainer: {
                style: {
                  border: "none",
                },
              },
            }}
          >
            <Panel title="1. Playground">
              Introduction of the Playground will be our first step. <br />
              <br />
              Getting feedback about experiences will prove very important in building a bug free, enjoyable Playground.
            </Panel>
            <Panel title="2. Testnet Minting">
              Having passed the Playground phase, we will be minting on the Testnet (Goerli).
              <br />
              <br /> This phase will be used to construct the ideal NFT data template, for example what properties to
              include in metadata etc.
            </Panel>
            <Panel title="3. Mainnet Minting">
              Having succesfully passed the Testnet minting phase and established the ideal NFT data template, we hope
              to be in a position to switch minting over to the Mainnet.
            </Panel>
            <Panel title="4. Analytics">
              Building an API to become a source for valuable insights that produces reports on traits, extensions and
              many more Playground interactions.
            </Panel>
            <Panel title="5. Noundry Traits">
              Introducing Noundry traits to the Playground will be our first step in the roadmap in terms of embedment.
              <br />
              <br />
              It will be the first experiment in embedding traits from artists outside the DAO traits.
            </Panel>
            <Panel title="6. Artists Traits">
              Having seen the Noundry experiment be a success, we will look to replicate it with individual artists from
              various extensions. <br />
              <br /> We will welcome individual artists to contribute their own traits to the Playground where they will
              receive <strong>X% (TBD)</strong> from the mint price, of which a mint includes their trait.
            </Panel>
            <Panel title="7. Lost Nouns Collection">
              Successfully embedding artists and their traits will be a huge milestone. However, we won't stop there. We
              want to go a step further and embed collections by having their own builder available on the website.{" "}
              <br />
              <br />
              We will run our first trial with the Lost Nouns collection.
            </Panel>
            <Panel title="7. Nounish Collections">
              Once happy with the <strong>Lost Nouns</strong> collection trial, we will open up the Playground to other
              Nounish collections and having their own Playground.
            </Panel>
          </Accordion>
        </Block>
      </Scrollable>
    </Block>
  )
}
