function DownloadIcon({ size, color = "#000000" }: { size: number; color?: string }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4H13V5V6V7V8V9V10V11V12H14V11H15V10H16H17V11V12H16V13H15V14H14V15H13V16H12H11V15H10V14H9V13H8V12H7V11V10H8H9V11H10V12H11V11V10V9V8V7V6V5V4H12ZM5 16H4H3V17V18V19V20V21H4H5H6H7H8H9H10H11H12H13H14H15H16H17H18H19H20H21V20V19V18V17V16H20H19V17V18V19H18H17H16H15H14H13H12H11H10H9H8H7H6H5V18V17V16Z"
        fill={color}
      />
    </svg>
  )
}

export default DownloadIcon
