import useAppContext from "~/hooks/useAppContext"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import { Block } from "baseui/block"
import { PanelType } from "~/constants/app-options"
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip"
import FAQIcon from "~/components/Icons/FAQIcon"
import TwitterIcon from "~/components/Icons/TwitterIcon"
import RoadmapIcon from "~/components/Icons/RoadmapIcon"
import { Button, SIZE, KIND } from "baseui/button"
import { useStyletron } from "baseui"

export default function () {
  const { activePanel, setActivePanel } = useAppContext()
  const [css, theme] = useStyletron()
  const setIsSidebarOpen = useSetIsSidebarOpen()

  return (
    <Block
      $style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        padding: "0 30px 0 12px",
        justifyContent: "flex-end",
        background: `${theme.colors.mono400}`,
        borderBottom: `1px solid ${theme.colors.mono200}`,
      }}
    >
      {/* <Button
        size={SIZE.mini}
        kind={KIND.tertiary}
        onClick={() => {
          setIsSidebarOpen(true)
          setActivePanel(PanelType.ROADMAP)
        }}
      >
        <RoadmapIcon size={24} fill={theme.colors.negative} />
        <span style={{ paddingLeft: "10px" }}>Roadmap</span>
      </Button> */}
      {/* <StatefulTooltip
        placement={PLACEMENT.bottom}
        showArrow={true}
        accessibilityType={"tooltip"}
        content="Frequently Asked Questions"
        overrides={{
          Inner: {
            style: {
              color: theme.colors.white,
            },
          },
        }}
      >
        <Button size={SIZE.mini} kind={KIND.tertiary} onClick={() => setActivePanel(PanelType.FAQ)}>
          <FAQIcon size={24} fill={theme.colors.negative} />
        </Button>
      </StatefulTooltip> */}
      <a href="https://twitter.com/buildyournoun" target="_blank">
        <Button size={SIZE.mini} kind={KIND.tertiary}>
          <TwitterIcon size={24} fill={theme.colors.negative} />
          {/* <span style={{ paddingLeft: "10px" }}>Twitter</span> */}
        </Button>
      </a>
    </Block>
  )
}
