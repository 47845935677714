import { fabric } from "fabric"
import { isArray, pick } from "lodash"
import { nanoid } from "nanoid"
import Base from "./Base"
import { ILayer, ILayerOptions } from "@buildyournoun/types"
import { copyStyleProps, getCopyStyleCursor, LayerType } from "../common/constants"
import { Direction, GradientOptions, ScaleType, ShadowOptions, Size } from "../common/interfaces"
import ObjectImporter from "../utils/object-importer"
import setObjectGradient, { setObjectShadow } from "../utils/fabric"

class Objects extends Base {
  private clipboard: any
  public isCut: any
  private copyStyleClipboard: any

  public add = async <T>(item: T) => {
    const { canvas } = this
    const options = this.editor.frame.options
    const objectImporter = new ObjectImporter(this.editor)
    const refItem = item as unknown as ILayer
    const object: fabric.Object = await objectImporter.import(refItem, options)
    if (this.config.clipToFrame) {
      const frame = this.editor.frame.frame
      object.clipPath = frame
    }

    this.remove(refItem.id)

    object.center()
    canvas.add(object)

    Object.keys(this.config.positions!).forEach((key) => {
      this.bringToFront(key)
    })

    this.state.setActiveObject(object)
    canvas.setActiveObject(object)
    this.updateContextObjects()
    this.editor.history.save()

    if (object.type === "StaticVideo") {
      setTimeout(() => {
        this.canvas.requestRenderAll()
      }, 500)
    }
  }
  /**
   *
   * @param options object properties to be updated
   * @param id if provided, will update the update by id
   */
  public update = (options: Partial<ILayerOptions>, id?: string) => {
    const frame = this.editor.frame.frame
    let refObject = this.canvas.getActiveObject()
    if (id) {
      refObject = this.findOneById(id)
    }
    const canvas = this.canvas
    if (refObject) {
      for (const property in options) {
        if (property === "angle" || property === "top" || property === "left") {
          if (property === "angle") {
            // @ts-ignore
            refObject.rotate(options["angle"])
            canvas.requestRenderAll()
          } else {
            // @ts-ignore
            refObject.set(property as "top" | "left", options[property])
            canvas.requestRenderAll()
          }
        } else if (property === "clipToFrame") {
          if (options["clipToFrame"]) {
            refObject.set("clipPath", frame)
          } else {
            refObject.set("clipPath", null)
          }
        } else {
          if (refObject.type === LayerType.ACTIVE_SELECTION && refObject._objects) {
            refObject._objects.forEach((object) => {
              if (property === "metadata") {
                object.set("metadata", {
                  ...object.metadata,
                  ...options["metadata"],
                })
              } else {
                // @ts-ignore
                object.set(property, options[property])
              }
              object.setCoords()
            })
          } else {
            if (property === "metadata") {
              refObject.set("metadata", {
                ...refObject.metadata,
                ...options[property],
              })
            } else {
              // @ts-ignore
              refObject.set(property, options[property])
            }
            refObject.setCoords()
          }
        }
        // @ts-ignore
        refObject.set(property as keyof fabric.Object, options[property])
        canvas.requestRenderAll()
      }
      this.editor.history.save()
    }
  }

  public clear = () => {
    const frame = this.editor.frame.frame
    this.canvas.getObjects().forEach((object) => {
      if (object.type !== LayerType.FRAME) {
        this.canvas.remove(object)
      }
    })
    frame.set({
      fill: "#ffffff",
    })
    this.canvas.renderAll()
  }

  public reset = () => {
    const background = this.editor.frame.background

    this.canvas.getObjects().forEach((object) => {
      if (object.type !== LayerType.FRAME && object.type !== LayerType.BACKGROUND) {
        this.canvas.remove(object)
      }
    })
    background?.set({
      fill: "#ffffff",
    })
    this.canvas.renderAll()
    this.editor.history.reset()
  }

  public select = (id?: string) => {
    this.canvas.discardActiveObject()
    if (id) {
      const [object] = this.findById(id) as fabric.Object[]
      if (object) {
        this.canvas.disableEvents()
        this.canvas.setActiveObject(object)
        if (object.group) {
          object.hasControls = false
        }
        this.canvas.enableEvents()
        this.canvas.requestRenderAll()

        const activeObject = this.canvas.getActiveObject()
        this.state.setActiveObject(activeObject)
      }
    } else {
      const filteredObjects = this.canvas.getObjects().filter((object) => {
        if (object.type === LayerType.FRAME) {
          return false
        } else if (!object.evented) {
          return false
        } else if (object.locked) {
          return false
        }
        return true
      })
      if (!filteredObjects.length) {
        return
      }
      if (filteredObjects.length === 1) {
        this.canvas.setActiveObject(filteredObjects[0])
        this.canvas.renderAll()
        this.state.setActiveObject(filteredObjects[0])
        return
      }
      const activeSelection = new fabric.ActiveSelection(filteredObjects, {
        canvas: this.canvas,
      }) as fabric.Object
      this.canvas.setActiveObject(activeSelection)
      this.canvas.renderAll()
      this.state.setActiveObject(activeSelection)
    }
  }

  public deselect = () => {
    this.canvas.discardActiveObject()
    this.canvas.requestRenderAll()
    this.state.setActiveObject(null)
  }

  public move(direction: Direction, value: number, id?: string) {
    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      const updatedPosition = refObject[direction] + value
      refObject.set(direction, updatedPosition)
      this.editor.history.save()
    }
  }

  public position(position: Direction, value: number, id?: string) {
    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      refObject.set(position, value)
      this.editor.history.save()
    }
  }

  public resize(size: Size, value: number, id?: string) {
    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    if (id) {
      refObject = this.findOneById(id)
    }
    if (size === "width") {
      refObject.set("scaleX", value / refObject.width)
    }
    if (size === "height") {
      refObject.set("scaleY", value / refObject.height)
    }
  }

  public scale(type: ScaleType, id?: string) {
    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    const { width, height } = this.editor.frame.frame
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      if (type === "fit") {
        refObject.set({
          scaleX: width / refObject.width,
          scaleY: height / refObject.height,
        })
      }
      if (type === "fill") {
        const proportion = height / refObject.height
        refObject.set({
          scaleY: height / refObject.height,
          scaleX: (refObject.width * proportion) / refObject.width,
        })
      }
      refObject.center()
    }
  }

  public cut = () => {
    this.copy()
    this.isCut = true
    this.remove()
  }

  public copy = () => {
    const activeObject = this.canvas.getActiveObject()
    if (activeObject) {
      this.clipboard = activeObject
    }
  }

  public copyById = (id: string) => {
    const object = this.findOneById(id)
    if (object) {
      this.clipboard = object
    }
  }

  public clone = () => {
    if (this.canvas) {
      const activeObject = this.canvas.getActiveObject()
      const frame = this.editor.frame.frame

      this.canvas.discardActiveObject()

      // @ts-ignore
      this.duplicate(activeObject, frame, (duplicates) => {
        const selection = new fabric.ActiveSelection(duplicates, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.canvas.requestRenderAll()
      })
    }
  }

  public cloneAudio = (id: string) => {
    const object = this.findOneById(id)
    const frame = this.editor.frame.frame
    this.deselect()
    this.duplicate(object, frame, (duplicates) => {
      this.canvas.requestRenderAll()
      this.updateContextObjects()
    })
  }

  private duplicate(object: fabric.Object, frame: fabric.Object, callback: (clones: fabric.Object[]) => void): void {
    if (object instanceof fabric.Group && object.type !== LayerType.STATIC_VECTOR) {
      const objects: fabric.Object[] = (object as fabric.Group).getObjects()
      const duplicates: fabric.Object[] = []
      for (let i = 0; i < objects.length; i++) {
        this.duplicate(objects[i], frame, (clones) => {
          duplicates.push(...clones)
          if (i === objects.length - 1) {
            callback(duplicates)
          }
        })
      }
    } else {
      object.clone(
        (clone: fabric.Object) => {
          clone.clipPath = undefined
          clone.id = nanoid()
          clone.set({
            left: object.left! + 10,
            top: object.top! + 10,
          })
          if (this.config.clipToFrame) {
            const frame = this.editor.frame.frame
            clone.clipPath = frame
          }
          this.canvas.add(clone)
          callback([clone])
        },
        ["keyValues", "src"]
      )
    }
  }

  public paste = () => {
    const object = this.clipboard
    if (object) {
      const frame = this.editor.frame.frame
      this.canvas.discardActiveObject()
      this.duplicate(object, frame, (duplicates) => {
        const selection = new fabric.ActiveSelection(duplicates, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.canvas.requestRenderAll()
        this.updateContextObjects()
      })
    }
  }

  /**`
   * Remove active object
   */
  public remove = (id?: string) => {
    let refObject = this.canvas.getActiveObjects()

    if (id) {
      refObject = this.findOneById(id)
    }

    if (isArray(refObject)) {
      refObject.forEach((obj) => {
        this.canvas.remove(obj)
      })
    } else {
      this.canvas.remove(refObject)
    }

    this.canvas.discardActiveObject().renderAll()
    this.editor.history.save()
    this.updateContextObjects()
  }

  public list = () => {
    const objects = this.canvas.getObjects()
    const filteredObjects = objects.filter((o) => {
      return o.type !== LayerType.FRAME && o.type !== LayerType.BACKGROUND
    })
    return filteredObjects
  }

  public copyStyle = () => {
    const activeObject = this.canvas.getActiveObject()
    if (activeObject) {
      //  @ts-ignore
      const clonableProps = copyStyleProps[activeObject.type]
      const clonedProps = pick(activeObject.toJSON(), clonableProps)

      this.copyStyleClipboard = {
        objectType: activeObject.type,
        props: clonedProps,
      }

      this.editor.frame.setHoverCursor(getCopyStyleCursor())
      this.canvas.hoverCursor = getCopyStyleCursor()
      this.canvas.defaultCursor = getCopyStyleCursor()
    }
  }

  public pasteStyle = () => {
    const activeObject = this.canvas.getActiveObject()
    if (activeObject && this.copyStyleClipboard) {
      if (activeObject.type === this.copyStyleClipboard.objectType) {
        const { fill, ...basicProps } = this.copyStyleClipboard.props
        activeObject.set(basicProps)

        if (fill) {
          if (fill.type) {
            activeObject.set({ fill: new fabric.Gradient(fill) })
          } else {
            activeObject.set({ fill })
          }
        }
      }
    }
    this.copyStyleClipboard = null
    this.editor.frame.setHoverCursor("default")
    this.canvas.hoverCursor = "move"
    this.canvas.defaultCursor = "default"
  }

  /**
   * Moves an object or a selection up in stack of drawn objects.
   */
  public bringForward = (id?: string) => {
    let refObject = this.canvas.getActiveObject()
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      this.canvas.bringForward(refObject)
    }
  }

  public bringForwardById = (id: string) => {
    this.canvas.getObjects().forEach((o) => {
      if (o.id === id) {
        this.canvas.bringForward(o)
      }
    })
  }

  /**
   * Moves an object or the objects of a multiple selection to the top of the stack of drawn objects
   */
  public bringToFront = (id?: string) => {
    let refObject = this.canvas.getActiveObject()
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      this.canvas.bringToFront(refObject)
    }
  }

  /**
   * Moves an object or a selection down in stack of drawn objects.
   */
  public sendBackwards = (id?: string) => {
    const objects = this.canvas.getObjects()
    let refObject = this.canvas.getActiveObject()
    if (id) {
      refObject = this.findOneById(id)
    }

    const index = objects.findIndex((o) => o === refObject)

    if (refObject && index > 2) {
      this.canvas.sendBackwards(refObject)
    }
  }

  /**
   * Moves an object to specified level in stack of drawn objects.
   */
  public sendToBack = (id?: string) => {
    let refObject = this.canvas.getActiveObject()
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      refObject.moveTo(2)
    }
  }

  /**
   * Moves an object to the top of the frame. If multiple objects are selected,
   * will move all objects to the top of the selection.
   */
  public alignTop = (id?: string) => {
    const frame = this.editor.frame.frame

    let refObject = this.canvas.getActiveObject()
    if (id) {
      refObject = this.findOneById(id)
    }

    if (refObject) {
      if (refObject.type === LayerType.ACTIVE_SELECTION) {
        const selectedObjects = refObject._objects as fabric.Object[]
        const refTop = refObject.top
        this.canvas.discardActiveObject()
        selectedObjects.forEach((object) => {
          const currentObject = object
          currentObject.set({
            top: refTop,
          })
        })
        const selection = new fabric.ActiveSelection(selectedObjects, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.state.setActiveObject(selection)
      } else {
        const currentObject = refObject
        currentObject.set({
          top: frame.top,
        })
      }
      this.canvas.requestRenderAll()
    }
  }
  /**
   * Moves an object to the middle of the frame. If multiple objects are selected,
   * will move all objects to the middle of the selection.
   */
  public alignMiddle = (id?: string) => {
    const frame = this.editor.frame.frame
    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    if (id) {
      refObject = this.findOneById(id)
    }

    if (refObject) {
      if (refObject.type === LayerType.ACTIVE_SELECTION) {
        const selectedObjects = refObject._objects as fabric.Object[]
        const refTop = refObject.top
        const refHeight = refObject.height
        this.canvas.discardActiveObject()
        selectedObjects.forEach((object) => {
          const currentObject = object
          const currentObjectHeight = currentObject.getScaledHeight()
          currentObject.set({
            top: refTop + refHeight / 2 - currentObjectHeight / 2,
          })
        })
        const selection = new fabric.ActiveSelection(selectedObjects, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.state.setActiveObject(selection)
      } else {
        const currentObject = refObject
        const currentObjectHeight = currentObject.getScaledHeight()
        currentObject.set({
          top: frame.top + frame.height / 2 - currentObjectHeight / 2,
        })
      }
      this.canvas.requestRenderAll()
    }
  }

  /**
   * Moves an object to the bottom of the frame. If multiple objects are selected,
   * will move all objects to the bottom of the selection.
   */
  public alignBottom = (id?: string) => {
    const frame = this.editor.frame.frame
    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    if (id) {
      refObject = this.findOneById(id)
    }

    if (refObject) {
      if (refObject.type === LayerType.ACTIVE_SELECTION) {
        const selectedObjects = refObject._objects as fabric.Object[]
        const refTop = refObject.top
        const refHeight = refObject.height
        this.canvas.discardActiveObject()
        selectedObjects.forEach((object) => {
          const currentObject = object
          const currentObjectHeight = currentObject.getScaledHeight()
          currentObject.set({
            top: refTop + refHeight - currentObjectHeight,
          })
        })
        const selection = new fabric.ActiveSelection(selectedObjects, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.state.setActiveObject(selection)
      } else {
        const currentObject = refObject
        const currentObjectHeight = currentObject.getScaledHeight()
        currentObject.set({
          top: frame.top + frame.height - currentObjectHeight,
        })
      }
      this.canvas.requestRenderAll()
    }
  }

  /**
   * Moves an object to the left of the frame. If multiple objects are selected,
   * will move all objects to the left of the selection.
   */
  public alignLeft = (id?: string) => {
    const frame = this.editor.frame.frame
    let refObject = this.canvas.getActiveObject()
    if (id) {
      refObject = this.findOneById(id)
    }

    if (refObject) {
      if (refObject.type === LayerType.ACTIVE_SELECTION) {
        const selectedObjects = refObject._objects as fabric.Object[]
        const refLeft = refObject.left
        this.canvas.discardActiveObject()
        selectedObjects.forEach((object) => {
          const currentObject = object
          currentObject.set({
            left: refLeft,
          })
        })
        const selection = new fabric.ActiveSelection(selectedObjects, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.state.setActiveObject(selection)
      } else {
        const currentObject = refObject
        currentObject.set({
          left: frame.left,
        })
      }
      this.canvas.requestRenderAll()
    }
  }

  /**
   * Moves an object to the center of the frame. If multiple objects are selected,
   * will move all objects to the center of the selection.
   */
  public alignCenter = (id?: string) => {
    const frame = this.editor.frame.frame
    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    if (id) {
      refObject = this.findOneById(id)
    }

    if (refObject) {
      if (refObject.type === LayerType.ACTIVE_SELECTION) {
        const selectedObjects = refObject._objects
        const refLeft = refObject.left
        const refWidth = refObject.width
        this.canvas.discardActiveObject()
        selectedObjects.forEach((object) => {
          const currentObject = object
          const currentObjectWidth = currentObject.getScaledWidth()
          currentObject.set({
            left: refLeft + refWidth / 2 - currentObjectWidth / 2,
          })
        })
        const selection = new fabric.ActiveSelection(selectedObjects, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.state.setActiveObject(selection)
      } else {
        const currentObject = refObject
        const currentObjectWidth = currentObject.getScaledWidth()
        currentObject.set({
          left: frame.left + frame.width / 2 - currentObjectWidth / 2,
        })
      }
      this.canvas.requestRenderAll()
    }
  }

  /**
   * Moves an object to the right of the frame. If multiple objects are selected,
   * will move all objects to the right of the selection.
   */
  public alignRight = (id?: string) => {
    const frame = this.editor.frame.frame
    let refObject = this.canvas.getActiveObject() as Required<fabric.Object>
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      if (refObject.type === LayerType.ACTIVE_SELECTION) {
        const selectedObjects = refObject._objects as fabric.Group[]
        const refLeft = refObject.left
        const refWidth = refObject.width
        this.canvas.discardActiveObject()
        selectedObjects.forEach((object) => {
          const currentObject = object
          const currentObjectWidth = currentObject.getScaledWidth()
          currentObject.set({
            left: refLeft + refWidth - currentObjectWidth,
          })
        })
        const selection = new fabric.ActiveSelection(selectedObjects, {
          canvas: this.canvas,
        }) as fabric.Object
        this.canvas.setActiveObject(selection)
        this.state.setActiveObject(selection)
      } else {
        const currentObject = refObject
        const currentObjectWidth = currentObject.getScaledWidth()
        currentObject.set({
          left: frame.left + frame.width - currentObjectWidth,
        })
      }
      this.canvas.requestRenderAll()
    }
  }

  /**
   * Set object shadow
   * @param options ShadowOptions
   */
  public setShadow = (options: ShadowOptions) => {
    const activeObject = this.canvas.getActiveObject()
    if (activeObject instanceof fabric.Group && activeObject.type !== LayerType.STATIC_VECTOR) {
      // @ts-ignore
      activeObject._objects.forEach((object) => {
        setObjectShadow(object, options)
      })
    } else {
      setObjectShadow(activeObject, options)
    }
    this.canvas.requestRenderAll()
    this.editor.history.save()
  }

  /**
   * Set object fill as gradient
   * @param param GradientOptions
   */
  public setGradient = ({ angle, colors }: GradientOptions) => {
    const activeObject = this.canvas.getActiveObject()
    if (activeObject instanceof fabric.Group) {
      // @ts-ignore
      activeObject._objects.forEach((object) => {
        setObjectGradient(object, angle, colors)
      })
    } else {
      // @ts-ignore
      setObjectGradient(activeObject, angle, colors)
    }
    this.canvas.requestRenderAll()
    this.editor.history.save()
  }

  /**
   * Group selected objects
   */
  public group = () => {
    const activeObject = this.canvas.getActiveObject() as fabric.ActiveSelection
    if (!activeObject) {
      return
    }
    if (activeObject.type !== LayerType.ACTIVE_SELECTION) {
      return
    }

    activeObject.toGroup()
    this.canvas.requestRenderAll()
    this.editor.history.save()

    const groupedActiveObject = this.canvas.getActiveObject()
    // @ts-ignore
    groupedActiveObject.set({
      name: "group",
      id: nanoid(),
      // @ts-ignore
      subTargetCheck: true,
    })
    this.updateContextObjects()
  }

  public ungroup = () => {
    const frame = this.editor.frame.frame
    const activeObject = this.canvas.getActiveObject() as fabric.ActiveSelection
    if (!activeObject) {
      return
    }
    if (activeObject.type !== LayerType.GROUP.toLowerCase()) {
      return
    }

    activeObject.clipPath = null
    const activeSelection = activeObject.toActiveSelection()
    // @ts-ignore
    activeSelection._objects.forEach((object) => {
      object.clipPath = frame
    })
    this.state.setActiveObject(activeSelection)
    this.canvas.requestRenderAll()
    this.editor.history.save()
    this.updateContextObjects()
  }

  /**
   * Lock object movement and disable controls
   */
  public lock = (id?: string) => {
    let refObject = this.canvas.getActiveObject() as fabric.Object | fabric.ActiveSelection
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      if (refObject._objects) {
        refObject._objects.forEach((object) => {
          object.set({
            hasControls: false,
            lockMovementY: true,
            lockMovementX: true,
            locked: true,
          })
        })
        // @ts-ignore
        refObject.set({
          hasControls: false,
          lockMovementY: true,
          lockMovementX: true,
          locked: true,
        })
      } else {
        // @ts-ignore
        refObject.set({
          hasControls: false,
          lockMovementY: true,
          lockMovementX: true,
          locked: true,
        })
      }
      this.canvas.requestRenderAll()
      this.editor.history.save()
    }
  }

  /**
   * Unlock active object
   */
  public unlock = (id?: string) => {
    let refObject = this.canvas.getActiveObject() as fabric.Object | fabric.ActiveSelection
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject) {
      if (refObject?._objects) {
        refObject._objects.forEach((object) => {
          object.set({
            hasControls: true,
            lockMovementY: false,
            lockMovementX: false,
            locked: false,
          })
        })
        // @ts-ignore
        refObject.set({
          hasControls: true,
          lockMovementY: false,
          lockMovementX: false,
          locked: false,
        })
      } else {
        // @ts-ignore
        refObject.set({
          hasControls: true,
          lockMovementY: false,
          lockMovementX: false,
          locked: false,
        })
      }
      this.canvas.requestRenderAll()
      this.editor.history.save()
    }
  }

  public findByName = (name: string) => {
    return this.canvas.getObjects().filter((o) => o.name === name)
  }

  public removeByName = (name: string) => {
    this.canvas.getObjects().forEach((o) => {
      if (o.name === name) {
        this.canvas.remove(o)
        this.editor.history.save()
      }
    })
    this.canvas.requestRenderAll()
  }

  public findByIdInObjecs = (id: string, objects: fabric.Object[]): any => {
    let item = null

    for (const object of objects) {
      if (object.id === id) {
        item = object
        break
      }
      if (object.type === "group") {
        // @ts-ignore
        const itemInGroup = this.findByIdInObjecs(id, object._objects)
        if (itemInGroup) {
          item = itemInGroup
          break
        }
      }
    }
    return item
  }
  public findById = (id: string) => {
    const objects = this.canvas.getObjects()
    const object = this.findByIdInObjecs(id, objects)
    return [object]
  }

  public findOneById = (id: string) => {
    const objects = this.findById(id)
    return objects[0]
  }

  public removeById = (id: string) => {
    this.canvas.getObjects().forEach((o) => {
      if (o.id === id) {
        this.canvas.remove(o)
        this.editor.history.save()
        this.updateContextObjects()
      }
    })
    this.canvas.requestRenderAll()
  }

  // Text exclusive hooks
  public toUppercase(id?: string) {
    let refObject = this.canvas.getActiveObject() as fabric.StaticText
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject && refObject.type === LayerType.STATIC_TEXT) {
      if (refObject.isEditing) {
        refObject.hiddenTextarea!.value = refObject.hiddenTextarea!.value.toUpperCase()
        refObject.updateFromTextArea()
        this.canvas.requestRenderAll()
        return
      }

      const text = refObject.text
      refObject.text = text!.toUpperCase()
      this.canvas.requestRenderAll()
    }
  }

  // Text exclusive hooks
  public toLowerCase(id?: string) {
    let refObject = this.canvas.getActiveObject() as fabric.StaticText
    if (id) {
      refObject = this.findOneById(id)
    }
    if (refObject && refObject.type === LayerType.STATIC_TEXT) {
      if (refObject.isEditing) {
        refObject.hiddenTextarea!.value = refObject.hiddenTextarea!.value.toLowerCase()
        refObject.updateFromTextArea()
        this.canvas.requestRenderAll()
        return
      }

      const text = refObject.text
      refObject.text = text!.toLowerCase()
      this.canvas.requestRenderAll()
    }
  }

  public updateContextObjects = () => {
    const objects = this.canvas.getObjects()
    const filteredObjects = objects.filter((o) => {
      return o.type !== "Frame" && o.type !== "Background"
    })
    this.state.setObjects(filteredObjects)
  }
}

export default Objects
