"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getImagesFromType = exports.getTraitSeed = void 0;
const nouns_assets_1 = require("@buildyournoun/nouns-assets");
const { bodies, accessories, heads, glasses } = nouns_assets_1.ImageDataDAOIsometric.images;
const getTraitSeed = (layers) => {
    const traits = [];
    const seed = {
        background: 0,
        body: bodies.findIndex((image) => image.filename === "zero"),
        accessory: accessories.findIndex((image) => image.filename === "zero"),
        head: heads.findIndex((image) => image.filename === "zero"),
        glasses: glasses.findIndex((image) => image.filename === "zero"),
    };
    layers.slice(1).forEach((layer) => {
        const images = (0, exports.getImagesFromType)(layer.id);
        const traitIndex = images.images.findIndex((image) => image.filename === layer.name);
        if (traitIndex !== -1) {
            const trait = images.images[traitIndex];
            traits.push(trait);
            seed[images.seed] = traitIndex;
        }
    });
    return {
        traits,
        seed,
    };
};
exports.getTraitSeed = getTraitSeed;
const getImagesFromType = (type) => {
    switch (type) {
        case "bodies":
            return { images: bodies, seed: "body" };
        case "accessories":
            return { images: accessories, seed: "accessory" };
        case "heads":
            return { images: heads, seed: "head" };
        case "glasses":
            return { images: glasses, seed: "glasses" };
        default:
            return { images: [], seed: "" };
    }
};
exports.getImagesFromType = getImagesFromType;
