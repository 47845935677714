"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.controlPositionIcons = exports.drawHorizontalLineIcon = exports.drawVerticalLineIcon = exports.drawBottomRightIcon = exports.drawBottomLeftIcon = exports.drawTopLeftIcon = exports.drawTopRightIcon = exports.drawCircleIcon = void 0;
// @ts-nocheck
var fabric_1 = require("fabric");
function drawCircleIcon(ctx, left, top, __styleOverride, fabricObject) {
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric_1.fabric.util.degreesToRadians(fabricObject.angle));
    ctx.beginPath();
    ctx.lineCap = "round";
    ctx.lineWidth = 3;
    ctx.shadowBlur = 2;
    ctx.shadowColor = "black";
    ctx.arc(0, 0, 5.5, 0, 2 * Math.PI);
    ctx.fillStyle = "#ffffff";
    ctx.fill();
    ctx.restore();
}
exports.drawCircleIcon = drawCircleIcon;
function drawTopRightIcon(ctx, left, top, __styleOverride, fabricObject) {
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric_1.fabric.util.degreesToRadians(fabricObject.angle));
    ctx.beginPath();
    ctx.lineCap = "round";
    ctx.lineWidth = 3;
    ctx.shadowBlur = 2;
    ctx.shadowColor = "black";
    ctx.moveTo(0, 0);
    ctx.lineTo(0.5, 12);
    ctx.moveTo(0, 0);
    ctx.lineTo(-12, 0);
    ctx.strokeStyle = "#ffffff";
    ctx.stroke();
    ctx.restore();
}
exports.drawTopRightIcon = drawTopRightIcon;
function drawTopLeftIcon(ctx, left, top, __styleOverride, fabricObject) {
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric_1.fabric.util.degreesToRadians(fabricObject.angle));
    ctx.beginPath();
    ctx.lineCap = "round";
    ctx.lineWidth = 3;
    ctx.shadowBlur = 2;
    ctx.shadowColor = "black";
    ctx.moveTo(0, 0);
    ctx.lineTo(0.5, 12);
    ctx.moveTo(0, 0);
    ctx.lineTo(12, 0);
    ctx.strokeStyle = "#ffffff";
    ctx.stroke();
    ctx.restore();
}
exports.drawTopLeftIcon = drawTopLeftIcon;
function drawBottomLeftIcon(ctx, left, top, __styleOverride, fabricObject) {
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric_1.fabric.util.degreesToRadians(fabricObject.angle));
    ctx.beginPath();
    ctx.lineCap = "round";
    ctx.lineWidth = 3;
    ctx.shadowBlur = 2;
    ctx.shadowColor = "black";
    ctx.moveTo(0, 0);
    ctx.lineTo(0, -12);
    ctx.moveTo(0, 0);
    ctx.lineTo(12, 0);
    ctx.strokeStyle = "#ffffff";
    ctx.stroke();
    ctx.restore();
}
exports.drawBottomLeftIcon = drawBottomLeftIcon;
function drawBottomRightIcon(ctx, left, top, __styleOverride, fabricObject) {
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric_1.fabric.util.degreesToRadians(fabricObject.angle));
    ctx.beginPath();
    ctx.lineCap = "round";
    ctx.lineWidth = 3;
    ctx.shadowBlur = 2;
    ctx.shadowColor = "black";
    ctx.moveTo(0, 0);
    ctx.lineTo(0, -12);
    ctx.moveTo(0, 0);
    ctx.lineTo(-12, 0);
    ctx.strokeStyle = "#ffffff";
    ctx.stroke();
    ctx.restore();
}
exports.drawBottomRightIcon = drawBottomRightIcon;
function drawVerticalLineIcon(ctx, left, top, __styleOverride, fabricObject) {
    var size = 24;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric_1.fabric.util.degreesToRadians(fabricObject.angle));
    ctx.beginPath();
    ctx.lineCap = "round";
    ctx.lineWidth = 3;
    ctx.shadowBlur = 2;
    ctx.shadowColor = "black";
    ctx.moveTo(-0.5, -size / 4);
    ctx.lineTo(-0.5, -size / 4 + size / 2);
    ctx.strokeStyle = "#ffffff";
    ctx.stroke();
    ctx.restore();
}
exports.drawVerticalLineIcon = drawVerticalLineIcon;
function drawHorizontalLineIcon(ctx, left, top, __styleOverride, fabricObject) {
    var size = 24;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric_1.fabric.util.degreesToRadians(fabricObject.angle));
    ctx.beginPath();
    ctx.lineCap = "round";
    ctx.lineWidth = 3;
    ctx.shadowBlur = 2;
    ctx.shadowColor = "black";
    ctx.moveTo(-size / 4, -0.5);
    ctx.lineTo(-size / 4 + size / 2, -0.5);
    ctx.strokeStyle = "#ffffff";
    ctx.stroke();
    ctx.restore();
}
exports.drawHorizontalLineIcon = drawHorizontalLineIcon;
exports.controlPositionIcons = {
    tl: drawTopLeftIcon,
    t: drawHorizontalLineIcon,
    tr: drawTopRightIcon,
    r: drawVerticalLineIcon,
    br: drawBottomRightIcon,
    b: drawHorizontalLineIcon,
    bl: drawBottomLeftIcon,
    l: drawVerticalLineIcon,
};
