import React from "react"
import useAppContext from "~/hooks/useAppContext"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import useIsSidebarOpen from "~/hooks/useIsSidebarOpen"
import { PanelType } from "~/constants/app-options"
import TraitsIcon from "~/components/Icons/TraitsIcon"
import Compress from "~/components/Icons/Compress"
import PlusIcon from "~/components/Icons/PlusIcon"
import MinusIcon from "~/components/Icons/MinusIcon"
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip"
import { Button, KIND, SIZE } from "baseui/button"
import { useZoomRatio, useEditor, useObjects } from "@buildyournoun/react"
import { Block } from "baseui/block"
import { Slider } from "baseui/slider"
import { Input } from "baseui/input"
import { useStyletron } from "baseui"

interface Options {
  zoomRatio: number
}

export default function () {
  const { activePanel, setActivePanel } = useAppContext()
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const isSidebarOpen = useIsSidebarOpen()
  const [css, theme] = useStyletron()
  const objects = useObjects() as any

  const zoomMin = 10
  const zoomMax = 240
  const editor = useEditor()
  const [options, setOptions] = React.useState<Options>({
    zoomRatio: 20,
  })
  const zoomRatio: number = useZoomRatio()

  const handleChange = (type: string, value: any) => {
    if (value < 0) {
      editor.zoom.zoomToRatio(zoomMin / 100)
    } else if (value > zoomMax) {
      editor.zoom.zoomToRatio(zoomMax / 100)
    } else {
      editor.zoom.zoomToRatio(value / 100)
    }
  }

  React.useEffect(() => {
    setOptions({ ...options, zoomRatio: Math.round(zoomRatio * 100) })
  }, [zoomRatio])

  return (
    <Block
      $style={{
        height: "50px",
        background: theme.colors.mono400,
        borderTop: `1px solid ${theme.colors.mono200}`,
        display: "flex",
        padding: "10px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {/* <StatefulTooltip
          placement={PLACEMENT.right}
          showArrow={true}
          accessibilityType={"tooltip"}
          content={objects.length !== 0 ? "View Traits Selected" : "No Traits Selected"}
          overrides={{
            Inner: {
              style: {
                color: theme.colors.white,
              },
            },
          }}
        >
          <Button
            kind={KIND.tertiary}
            size={SIZE.compact}
            onClick={() => {
              if (objects.length !== 0) {
                setIsSidebarOpen(true)
                setActivePanel(PanelType.TRAITS)
              }
            }}
          >
            <TraitsIcon size={24} fill={theme.colors.negative} />
          </Button>
        </StatefulTooltip> */}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button kind={KIND.tertiary} size={SIZE.compact} onClick={() => editor.zoom.zoomToFit()}>
          <Compress size={18} />
        </Button>
        <Button kind={KIND.tertiary} size={SIZE.compact} onClick={() => editor.zoom.zoomOut()}>
          <MinusIcon size={24} />
        </Button>
        {/* <Slider
          overrides={{
            InnerThumb: () => null,
            ThumbValue: () => null,
            TickBar: () => null,
            Root: {
              style: { width: "140px" },
            },
            Thumb: {
              style: {
                height: "12px",
                width: "12px",
                paddingLeft: 0,
              },
            },
            Track: {
              style: {
                paddingLeft: 0,
                paddingRight: 0,
              },
            },
          }}
          value={[options.zoomRatio]}
          onChange={({ value }) => {
            handleChange("zoomRatio", value[0])
          }}
          min={zoomMin}
          max={zoomMax}
        /> */}
        <Button kind={KIND.tertiary} size={SIZE.compact} onClick={() => editor.zoom.zoomIn()}>
          <PlusIcon size={24} />
        </Button>
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
        {/* <Button kind={KIND.tertiary} size={SIZE.compact}>
          <Icons.Refresh size={16} />
        </Button> */}
        {/* <Button kind={KIND.tertiary} size={SIZE.compact}>
          <Icons.Undo size={22} />
        </Button> */}
        {/* <Button kind={KIND.tertiary} size={SIZE.compact}>
          <Icons.Redo size={22} />
        </Button> */}
        {/* <Button kind={KIND.tertiary} size={SIZE.compact}>
          <Icons.TimePast size={16} />
        </Button> */}
      </div>
    </Block>
  )
}
