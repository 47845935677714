import React from "react"
import ResizeObserver from "resize-observer-polyfill"
import useAppContext from "~/hooks/useAppContext"
import useEditorType from "~/hooks/useEditorType"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import Logo from "~/components/Icons/Logo"
import {
  getBackgrounds,
  getBodyTraits,
  getAccessoryTraits,
  getHeadTraits,
  getGlassesTraits,
} from "./store/slices/traits/actions"
import { useAppDispatch } from "./store/store"
import { useStyletron } from "baseui"

function Container({ children }: { children: React.ReactNode }) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const { isMobile, setIsMobile } = useAppContext()

  const { setScenes } = useDesignEditorContext()

  const [loaded, setLoaded] = React.useState<boolean>(false)
  const dispatch = useAppDispatch()
  const [css, theme] = useStyletron()
  const editorType = useEditorType()

  const updateMediaQuery = (value: number) => {
    if (!isMobile && value >= 1024) {
      setIsMobile(false)
    } else if (!isMobile && value < 1024) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  React.useEffect(() => {
    const containerElement = containerRef.current!
    const containerWidth = containerElement.clientWidth
    updateMediaQuery(containerWidth)
    const resizeObserver = new ResizeObserver((entries) => {
      const { width = containerWidth } = (entries[0] && entries[0].contentRect) || {}
      updateMediaQuery(width)
    })
    resizeObserver.observe(containerElement)
    return () => {
      if (containerElement) {
        resizeObserver.unobserve(containerElement)
      }
    }
  }, [])

  React.useEffect(() => {
    setScenes([])

    dispatch(getBackgrounds({ origin: editorType.toLocaleLowerCase() }))
    dispatch(getBodyTraits({ origin: editorType.toLocaleLowerCase() }))
    dispatch(getAccessoryTraits({ origin: editorType.toLocaleLowerCase() }))
    dispatch(getHeadTraits({ origin: editorType.toLocaleLowerCase() }))
    dispatch(getGlassesTraits({ origin: editorType.toLocaleLowerCase() }))
    setTimeout(() => {
      setLoaded(true)
    }, 1000)
  }, [editorType, setScenes])

  return (
    <div
      ref={containerRef}
      style={{
        background: theme.colors.mono100,
        flex: 1,
        display: "flex",
        height: "100vh",
        width: "100vw",
      }}
    >
      {loaded ? (
        isMobile ? (
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              fontFamily: "Dystopian",
              fontWeight: 700,
              color: theme.colors.negative,
            }}
          >
            <div>Desktop only right now. ❤️</div>
          </div>
        ) : (
          <>{children} </>
        )
      ) : (
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Logo size={96} theme={theme.colors.negative === "#ffffff" ? "dark" : "light"} fill="#000" animate={true} />
        </div>
      )}
    </div>
  )
}

export default Container
