"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const mappings = {
    a: 0,
    A: 1,
    b: 2,
    B: 3,
    c: 4,
    C: 5,
    d: 6,
    D: 7,
    e: 8,
    E: 9,
    f: 10,
    F: 11,
    g: 12,
    G: 13,
    h: 14,
    H: 15,
    i: 16,
    I: 17,
    j: 18,
    J: 19,
    k: 20,
    K: 21,
    l: 22,
    L: 23,
    m: 24,
    M: 25,
    n: 26,
    N: 27,
    o: 28,
    O: 29,
    p: 30,
    P: 31,
    q: 32,
    Q: 33,
    r: 34,
    R: 35,
    s: 36,
    S: 37,
    t: 38,
    T: 39,
    u: 40,
    U: 41,
    v: 42,
    V: 43,
    w: 44,
    W: 45,
    x: 46,
    X: 47,
    y: 48,
    Y: 49,
    z: 50,
    Z: 51,
    "=": 52,
    "+": 53,
    "/": 54,
    54: "/",
    53: "+",
    52: "=",
    51: "Z",
    50: "z",
    49: "Y",
    48: "y",
    47: "X",
    46: "x",
    45: "W",
    44: "w",
    43: "V",
    42: "v",
    41: "U",
    40: "u",
    39: "T",
    38: "t",
    37: "S",
    36: "s",
    35: "R",
    34: "r",
    33: "Q",
    32: "q",
    31: "P",
    30: "p",
    29: "O",
    28: "o",
    27: "N",
    26: "n",
    25: "M",
    24: "m",
    23: "L",
    22: "l",
    21: "K",
    20: "k",
    19: "J",
    18: "j",
    17: "I",
    16: "i",
    15: "H",
    14: "h",
    13: "G",
    12: "g",
    11: "F",
    10: "f",
    9: "E",
    8: "e",
    7: "D",
    6: "d",
    5: "C",
    4: "c",
    3: "B",
    2: "b",
    1: "A",
    0: "a",
};
exports.default = mappings;
