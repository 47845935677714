import React from "react"
import { Block } from "baseui/block"
import { useStyletron } from "baseui"

export default function ({ children }: { children: React.ReactNode }) {
  const [css, theme] = useStyletron()

  return (
    <Block
      $style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        background: theme.colors.mono100,
      }}
    >
      {children}
    </Block>
  )
}
