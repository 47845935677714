import { Trait } from "~/interfaces/common"
import { createAsyncThunk, createAction } from "@reduxjs/toolkit"
import api from "~/services/api"

export const setBackgrounds = createAction<string[]>("traits/setBackgrounds")
export const setBodyTraits = createAction<Trait[]>("traits/setBodyTraits")
export const setAccessoryTraits = createAction<Trait[]>("traits/setAccessoryTraits")
export const setHeadTraits = createAction<Trait[]>("traits/setHeadResources")
export const selectGlassesTraits = createAction<Trait[]>("traits/selectGlassesTraits")

export const getBackgrounds = createAsyncThunk<void, { origin: string }, { rejectValue: Record<string, string[]> }>(
  "resources/getBackgrounds",
  async ({ origin }, { rejectWithValue, dispatch }) => {
    try {
      const backgrounds = await api.getBackgrounds(origin)
      dispatch(setBackgrounds(backgrounds))
    } catch (err) {
      return rejectWithValue((err as any).response?.data?.error.data || null)
    }
  }
)

export const getBodyTraits = createAsyncThunk<void, { origin: string }, { rejectValue: Record<string, string[]> }>(
  "resources/getBodyTraits",
  async ({ origin }, { rejectWithValue, dispatch }) => {
    try {
      const bodyTraits = await api.getTraits("bodies", origin)
      dispatch(setBodyTraits(bodyTraits))
    } catch (err) {
      return rejectWithValue((err as any).response?.data?.error.data || null)
    }
  }
)

export const getAccessoryTraits = createAsyncThunk<void, { origin: string }, { rejectValue: Record<string, string[]> }>(
  "resources/getAccessoryTraits",
  async ({ origin }, { rejectWithValue, dispatch }) => {
    try {
      const accessoryTraits = await api.getTraits("accessories", origin)
      dispatch(setAccessoryTraits(accessoryTraits))
    } catch (err) {
      return rejectWithValue((err as any).response?.data?.error.data || null)
    }
  }
)

export const getHeadTraits = createAsyncThunk<void, { origin: string }, { rejectValue: Record<string, string[]> }>(
  "resources/getHeadTraits",
  async ({ origin }, { rejectWithValue, dispatch }) => {
    try {
      const headTraits = await api.getTraits("heads", origin)
      dispatch(setHeadTraits(headTraits))
    } catch (err) {
      return rejectWithValue((err as any).response?.data?.error.data || null)
    }
  }
)

export const getGlassesTraits = createAsyncThunk<void, { origin: string }, { rejectValue: Record<string, string[]> }>(
  "resources/getGlassesTraits",
  async ({ origin }, { rejectWithValue, dispatch }) => {
    try {
      const glassesTraits = await api.getTraits("glasses", origin)
      dispatch(selectGlassesTraits(glassesTraits))
    } catch (err) {
      return rejectWithValue((err as any).response?.data?.error.data || null)
    }
  }
)
