import Background from "./Background"
import Bodies from "./Bodies"
import Accessories from "./Accessories"
import Heads from "./Heads"
import Glasses from "./Glasses"
import Traits from "./Traits"
import FAQ from "./FAQ"
import Roadmap from "./Roadmap"

export default {
  Background: Background,
  Bodies: Bodies,
  Accessories: Accessories,
  Heads: Heads,
  Glasses: Glasses,
  Traits: Traits,
  FAQ: FAQ,
  Roadmap: Roadmap,
}
