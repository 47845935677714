import Graphic from "./Graphic"
import useEditorType from "~/hooks/useEditorType"

export default function () {
  const editorType = useEditorType()

  if (editorType === "NONE") {
    return <></>
  } else {
    return <Graphic />
  }
}
