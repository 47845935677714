"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.StaticImageObject = exports.ControlPositions = void 0;
// @ts-nocheck
var fabric_1 = require("fabric");
var controls_1 = require("./controls");
var ControlPositions;
(function (ControlPositions) {
    ControlPositions["TOP_LEFT"] = "tl";
    ControlPositions["TOP"] = "t";
    ControlPositions["TOP_RIGHT"] = "tr";
    ControlPositions["RIGHT"] = "r";
    ControlPositions["BOTTOM_RIGHT"] = "br";
    ControlPositions["BOTTOM"] = "b";
    ControlPositions["BOTTOM_LEFT"] = "bl";
    ControlPositions["LEFT"] = "l";
})(ControlPositions = exports.ControlPositions || (exports.ControlPositions = {}));
var StaticImageObject = /** @class */ (function (_super) {
    __extends(StaticImageObject, _super);
    function StaticImageObject() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._editingMode = false;
        _this.__editingImage = null;
        _this.cornerLengthEditing = 5;
        /**
         * Color of the corner stroke in editing mode.
         */
        _this.cornerStrokeColorEditing = "black";
        /**
         * Size of the corner stroke in editing mode.
         */
        _this.cornerSizeEditing = 2;
        return _this;
    }
    StaticImageObject.prototype.registerEditingEvents = function () {
        // this.on("mousedblclick", () => {
        //   if (!this._editingMode) {
        //     return this.enterEditingMode()
        //   } else {
        //     this.exitEditingMode()
        //   }
        // })
        // this.on("deselected", () => {
        //   this.exitEditingMode()
        // })
    };
    StaticImageObject.prototype.enterEditingMode = function () {
        var _this = this;
        if (this.selectable && this.canvas) {
            this._editingMode = true;
            this.clone(function (image) {
                var _a;
                image.clipPath = undefined;
                var element = image.getElement();
                var _b = image.top, top = _b === void 0 ? 0 : _b, _c = image.left, left = _c === void 0 ? 0 : _c, _d = image.cropX, cropX = _d === void 0 ? 0 : _d, _e = image.cropY, cropY = _e === void 0 ? 0 : _e, _f = image.scaleX, scaleX = _f === void 0 ? 1 : _f, _g = image.scaleY, scaleY = _g === void 0 ? 1 : _g;
                image.set({
                    top: top - cropY * scaleY,
                    left: left - cropX * scaleX,
                    height: element.height,
                    width: element.width,
                    cropX: 0,
                    cropY: 0,
                    opacity: 0.6,
                    selectable: true,
                    evented: false,
                    excludeFromExport: true,
                });
                _this.__editingImage = image;
                _this.canvas.add(_this.__editingImage);
                // this.on('moving', (this.__editingOnMoving = this.__editingOnMoving.bind(this)))
                _this.controls = _this.__editingControls();
                // this.fire('enter:editing', { target: this })
                (_a = _this.canvas) === null || _a === void 0 ? void 0 : _a.requestRenderAll();
            });
        }
    };
    StaticImageObject.prototype.exitEditingMode = function () {
        var _a;
        if (this.selectable && this.canvas) {
            this._editingMode = false;
            if (this.__editingImage) {
                this.canvas.remove(this.__editingImage);
                this.__editingImage = null;
            }
            this.off("moving", this.__editingOnMoving);
            this.controls = fabric_1.fabric.Object.prototype.controls;
            this.fire("exit:editing", { target: this });
            (_a = this.canvas) === null || _a === void 0 ? void 0 : _a.requestRenderAll();
        }
    };
    // @ts-ignore
    StaticImageObject.prototype.__editingControls = function () {
        var controls = Object.values(ControlPositions);
        return controls.map(this.__createEditingControl.bind(this));
    };
    StaticImageObject.prototype.__createEditingControl = function (position) {
        var cursor = position.replace("t", "s").replace("l", "e").replace("b", "n").replace("r", "w");
        return new fabric_1.fabric.Control({
            cursorStyle: cursor + "-resize",
            actionName: "edit_".concat(this.type),
            render: controls_1.controlPositionIcons[position],
            positionHandler: this.__editingControlPositionHandler.bind(this, position),
            actionHandler: this.__editingActionHandlerWrapper(position),
        });
    };
    StaticImageObject.prototype.__editingActionHandlerWrapper = function (position) {
        var _this = this;
        return function (_event, _transform, x, y) {
            _this.__editingSetCrop(position, x, y, true);
            return true;
        };
    };
    // @ts-ignore
    StaticImageObject.prototype.__editingOnMoving = function (event) {
        if (this._editingMode && event.pointer) {
            this.__editingSetCrop(ControlPositions.TOP_LEFT, this.left, this.top);
        }
    };
    StaticImageObject.prototype.__editingSetCrop = function (position, x, y, resize) {
        if (resize === void 0) { resize = false; }
        if (this.__editingImage) {
            var _a = this.__editingImage, _b = _a.top, top_1 = _b === void 0 ? 0 : _b, _c = _a.left, left = _c === void 0 ? 0 : _c, _d = _a.width, width = _d === void 0 ? 0 : _d, _e = _a.height, height = _e === void 0 ? 0 : _e, _f = _a.scaleX, scaleX = _f === void 0 ? 1 : _f, _g = _a.scaleY, scaleY = _g === void 0 ? 1 : _g;
            if (position.includes("t")) {
                var maxTop = top_1 + height * scaleY - (resize ? 0 : this.getScaledHeight());
                var minTop = Math.min(y, maxTop, this.top + this.getScaledHeight());
                this.top = Math.max(minTop, top_1);
                var cropY = Math.min((Math.min(Math.max(y, top_1), this.top) - top_1) / scaleY, height);
                if (resize) {
                    this.height = Math.max(0, Math.min(this.height + (this.cropY - cropY), height));
                }
                this.cropY = cropY;
            }
            else if (position.includes("b") && resize) {
                var minHeight = Math.min((y - top_1) / scaleY - this.cropY, height - this.cropY);
                this.height = Math.max(0, minHeight);
            }
            if (position.includes("l")) {
                var maxLeft = left + width * scaleX - (resize ? 0 : this.getScaledWidth());
                var minLeft = Math.min(x, maxLeft, this.left + this.getScaledWidth());
                this.left = Math.max(minLeft, left);
                var cropX = Math.min((Math.min(Math.max(x, left), this.left) - left) / scaleX, width);
                if (resize) {
                    this.width = Math.max(0, Math.min(this.width + (this.cropX - cropX), width));
                }
                this.cropX = cropX;
            }
            else if (position.includes("r") && resize) {
                var minWidth = Math.min((x - left) / scaleX - this.cropX, width - this.cropX);
                this.width = Math.max(0, minWidth);
            }
        }
    };
    StaticImageObject.prototype.__editingControlPositionHandler = function (position) {
        var xMultiplier = position.includes("l") ? -1 : position.length > 1 || position === "r" ? 1 : 0;
        var yMultiplier = position.includes("t") ? -1 : position.length > 1 || position === "b" ? 1 : 0;
        var x = (this.width / 2) * xMultiplier;
        var y = (this.height / 2) * yMultiplier;
        return fabric_1.fabric.util.transformPoint(new fabric_1.fabric.Point(x, y), fabric_1.fabric.util.multiplyTransformMatrices(this.canvas.viewportTransform, this.calcTransformMatrix()));
    };
    StaticImageObject.prototype.__renderEditingControl = function (position, ctx, left, top) {
        ctx.save();
        ctx.strokeStyle = this.cornerStrokeColorEditing;
        ctx.lineWidth = this.cornerSizeEditing;
        ctx.translate(left, top);
        if (this.angle) {
            ctx.rotate(fabric_1.fabric.util.degreesToRadians(this.angle));
        }
        ctx.beginPath();
        var x = position.includes("l") ? -ctx.lineWidth : position.includes("r") ? ctx.lineWidth : 0;
        var y = position.includes("t") ? -ctx.lineWidth : position.includes("b") ? ctx.lineWidth : 0;
        if (position === "b" || position === "t") {
            ctx.moveTo(x - this.cornerLengthEditing / 2, y);
            ctx.lineTo(x + this.cornerLengthEditing, y);
        }
        else if (position === "r" || position === "l") {
            ctx.moveTo(x, y - this.cornerLengthEditing / 2);
            ctx.lineTo(x, y + this.cornerLengthEditing);
        }
        else {
            if (position.includes("b")) {
                ctx.moveTo(x, y - this.cornerLengthEditing);
            }
            else if (position.includes("t")) {
                ctx.moveTo(x, y + this.cornerLengthEditing);
            }
            ctx.lineTo(x, y);
            if (position.includes("r")) {
                ctx.lineTo(x - this.cornerLengthEditing, y);
            }
            else if (position.includes("l")) {
                ctx.lineTo(x + this.cornerLengthEditing, y);
            }
        }
        ctx.stroke();
        ctx.restore();
    };
    //@ts-ignore
    StaticImageObject.prototype.initialize = function (element, options) {
        // @ts-ignore
        this.registerEditingEvents();
        //@ts-ignore
        _super.prototype.initialize.call(this, element, options);
        return this;
    };
    StaticImageObject.fromObject = function (options, callback) {
        fabric_1.fabric.util.loadImage(options.src, function (img) {
            // @ts-ignore
            return callback && callback(new fabric_1.fabric.StaticImage(img, options));
        }, null, 
        // @ts-ignore
        { crossOrigin: "anonymous" });
    };
    StaticImageObject.prototype.toObject = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        return _super.prototype.toObject.call(this, propertiesToInclude);
    };
    StaticImageObject.prototype.toJSON = function (propertiesToInclude) {
        if (propertiesToInclude === void 0) { propertiesToInclude = []; }
        return _super.prototype.toObject.call(this, propertiesToInclude);
    };
    StaticImageObject.type = "StaticImage";
    return StaticImageObject;
}(fabric_1.fabric.Image));
exports.StaticImageObject = StaticImageObject;
fabric_1.fabric.StaticImage = fabric_1.fabric.util.createClass(StaticImageObject, {
    type: StaticImageObject.type,
});
fabric_1.fabric.StaticImage.fromObject = StaticImageObject.fromObject;
