function ViewIcon({ size, fill = "currentColor" }: { size: number; fill?: string }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3H3V4V5V6V7V8V9V10V11V12V13V14V15V16V17V18V19V20V21H4H5H6H7H8H9H10H11H12H13H14H15H16H17H18H19H20H21V20V19V18V17V16V15V14V13V12V11V10V9V8V7V6V5V4V3H20H19H18H17H16H15H14H13H12H11H10H9H8H7H6H5H4ZM19 5V6V7V8V9V10V11V12V13H18V12H17V11H16H15V12H14V13H13V14H12V15H11V16H10V17H9V18H8V19H7H6H5V18V17V16V15V14V13V12V11V10V9V8V7V6V5H6H7H8H9H10H11H12H13H14H15H16H17H18H19ZM17 14H16H15V15H14V16H13V17H12V18H11V19H12H13H14H15H16H17H18H19V18V17V16H18V15H17V14ZM10 10V9V8H9H8V9V10H9H10ZM12 11H11V12H10H9H8H7V11H6V10V9V8V7H7V6H8H9H10H11V7H12V8V9V10V11Z"
        fill={fill}
      />
    </svg>
  )
}

export default ViewIcon
