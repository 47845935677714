function NounsLogo({ size }: { size: number }) {
  return (
    <svg
      width={160 * size}
      height={60 * size}
      viewBox="0 0 160 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="crispEdges"
    >
      <path d="M90 0H30V10H90V0Z" fill="#D53C5E" />
      <path d="M160 0H100V10H160V0Z" fill="#D53C5E" />
      <path d="M40 10H30V20H40V10Z" fill="#D53C5E" />
      <path d="M60 10H40V20H60V10Z" fill="white" />
      <path d="M80 10H60V20H80V10Z" fill="black" />
      <path d="M90 10H80V20H90V10Z" fill="#D53C5E" />
      <path d="M110 10H100V20H110V10Z" fill="#D53C5E" />
      <path d="M130 10H110V20H130V10Z" fill="white" />
      <path d="M150 10H130V20H150V10Z" fill="black" />
      <path d="M160 10H150V20H160V10Z" fill="#D53C5E" />
      <path d="M40 20H0V30H40V20Z" fill="#D53C5E" />
      <path d="M60 20H40V30H60V20Z" fill="white" />
      <path d="M80 20H60V30H80V20Z" fill="black" />
      <path d="M110 20H80V30H110V20Z" fill="#D53C5E" />
      <path d="M130 20H110V30H130V20Z" fill="white" />
      <path d="M150 20H130V30H150V20Z" fill="black" />
      <path d="M160 20H150V30H160V20Z" fill="#D53C5E" />
      <path d="M10 30H0V40H10V30Z" fill="#D53C5E" />
      <path d="M40 30H30V40H40V30Z" fill="#D53C5E" />
      <path d="M60 30H40V40H60V30Z" fill="white" />
      <path d="M80 30H60V40H80V30Z" fill="black" />
      <path d="M90 30H80V40H90V30Z" fill="#D53C5E" />
      <path d="M110 30H100V40H110V30Z" fill="#D53C5E" />
      <path d="M130 30H110V40H130V30Z" fill="white" />
      <path d="M150 30H130V40H150V30Z" fill="black" />
      <path d="M160 30H150V40H160V30Z" fill="#D53C5E" />
      <path d="M10 40H0V50H10V40Z" fill="#D53C5E" />
      <path d="M40 40H30V50H40V40Z" fill="#D53C5E" />
      <path d="M60 40H40V50H60V40Z" fill="white" />
      <path d="M80 40H60V50H80V40Z" fill="black" />
      <path d="M90 40H80V50H90V40Z" fill="#D53C5E" />
      <path d="M110 40H100V50H110V40Z" fill="#D53C5E" />
      <path d="M130 40H110V50H130V40Z" fill="white" />
      <path d="M150 40H130V50H150V40Z" fill="black" />
      <path d="M160 40H150V50H160V40Z" fill="#D53C5E" />
      <path d="M90 50H30V60H90V50Z" fill="#D53C5E" />
      <path d="M160 50H100V60H160V50Z" fill="#D53C5E" />
    </svg>
  )
}

export default NounsLogo
