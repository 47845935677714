"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hexToRgb = exports.rgbToHex = exports.toPaddedHex = void 0;
const toPaddedHex = (c, pad = 2) => {
    return c.toString(16).padStart(pad, "0");
};
exports.toPaddedHex = toPaddedHex;
const rgbToHex = (r, g, b) => {
    return `${(0, exports.toPaddedHex)(r)}${(0, exports.toPaddedHex)(g)}${(0, exports.toPaddedHex)(b)}`;
};
exports.rgbToHex = rgbToHex;
const hexToRgb = (hex) => {
    const hexNum = parseInt(hex, 16);
    const r = (hexNum >> 16) & 255;
    const g = (hexNum >> 8) & 255;
    const b = hexNum & 255;
    return [r, g, b, 1];
};
exports.hexToRgb = hexToRgb;
