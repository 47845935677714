import Background from "./Background"
import Search from "./Search"
import Images from "./Images"
import Logo from "./Logo"
import Compress from "./Compress"
import Expand from "./Expand"
import Undo from "./Undo"
import Redo from "./Redo"
import Layers from "./Layers"
import Refresh from "./Refresh"
import TimePast from "./TimePast"
import AddCircleOutline from "./AddCircleOutline"
import RemoveCircleOutline from "./RemoveCircleOutline"
import InformationCircleOutline from "./InformationCircleOutline"
import SwapHorizontal from "./SwapHorizontal"
import Add from "./Add"
import TraitsIcon from "./TraitsIcon"
import BackgroundIcon from "./BackgroundIcon"
import BodyIcon from "./BodyIcon"
import AccessoryIcon from "./AccessoryIcon"
import HeadIcon from "./HeadIcon"
import GlassesIcon from "./GlassesIcon"
import GlassesBYNIcon from "./GlassesBYNIcon"

class Icons {
  static Traits = TraitsIcon
  static Background = BackgroundIcon
  static Bodies = BodyIcon
  static Accessories = AccessoryIcon
  static Heads = HeadIcon
  static Glasses = GlassesBYNIcon
  static Search = Search
  static Images = Images
  static Logo = Logo
  static Compress = Compress
  static Expand = Expand
  static Undo = Undo
  static Redo = Redo
  static Refresh = Refresh
  static Layers = Layers
  static TimePast = TimePast
  static AddCircleOutline = AddCircleOutline
  static RemoveCircleOutline = RemoveCircleOutline
  static InformationCircleOutline = InformationCircleOutline
  static SwapHorizontal = SwapHorizontal
  static Add = Add
}

export default Icons
