export const BASE_ITEMS = [
  {
    id: "background",
    name: "Background",
    iconSize: 24,
  },
  {
    id: "bodies",
    name: "Bodies",
    iconSize: 24,
  },
  {
    id: "accessories",
    name: "Accessories",
    iconSize: 24,
  },
  {
    id: "heads",
    name: "Heads",
    iconSize: 24,
  },
  {
    id: "glasses",
    name: "Glasses",
    iconSize: 48,
  },
]

export enum PanelType {
  TRAITS = "Traits",
  BODIES = "Bodies",
  HEADS = "Heads",
  GLASSES = "Glasses",
  TEMPLATES = "Templates",
  BACKGROUND = "Background",
  FAQ = "FAQ",
  ROADMAP = "Roadmap",
}
