import React from "react"
import { Provider as ScenifyProvider } from "@buildyournoun/react"
import { Client as Styletron } from "styletron-engine-atomic"
import { Provider as StyletronProvider } from "styletron-react"
import { BaseProvider } from "baseui"
import { AppDarkTheme } from "~/themes"
import { store } from "./store/store"
import { Provider } from "react-redux"
import { AppProvider } from "./contexts/AppContext"
import { DesignEditorProvider } from "./contexts/DesignEditor"
import { I18nextProvider } from "react-i18next"
import i18next from "i18next"
import "~/translations"

const engine = new Styletron()

export default function ({ children }: { children: React.ReactNode }) {
  return (
    <Provider store={store}>
      <DesignEditorProvider>
        <AppProvider>
          <ScenifyProvider>
            <StyletronProvider value={engine}>
              <BaseProvider theme={AppDarkTheme}>
                <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
              </BaseProvider>
            </StyletronProvider>
          </ScenifyProvider>
        </AppProvider>
      </DesignEditorProvider>
    </Provider>
  )
}
