import React from "react"
import { useEditor, useObjects } from "@buildyournoun/react"
import { base64Encode } from "@buildyournoun/encryption"
import { styled, ThemeProvider, DarkTheme } from "baseui"
import { Theme } from "baseui/theme"
import { Button, KIND } from "baseui/button"
import { Popover } from "baseui/popover"
import { StatefulMenu } from "baseui/menu"
import { TriangleDown } from "baseui/icon"
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip"
import { PanelType } from "~/constants/app-options"
import Logo from "~/components/Icons/Logo"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import useAppContext from "~/hooks/useAppContext"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import TraitsIcon from "~/components/Icons/TraitsIcon"
import ViewIcon from "~/components/Icons/ViewIcon"
import DownloadIcon from "~/components/Icons/DownloadIcon"
import { Block } from "baseui/block"
import { useStyletron } from "baseui"
import { updateVisibleLayers } from "../Preview/Graphic"

const Container = styled<"div", {}, Theme>("div", () => ({}))

export default function () {
  const editor = useEditor()
  const objects = useObjects() as any

  const { setDisplayPreview } = useDesignEditorContext()
  const { activePanel, setActivePanel } = useAppContext()
  const setIsSidebarOpen = useSetIsSidebarOpen()

  const [css, theme] = useStyletron()

  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = React.useState(false)

  const downloadNoun = async (type: string) => {
    var template = editor.scene.exportToJSON()

    await updateVisibleLayers(editor, template)

    let image = (await editor.renderer.toSvg(template, {})) as string
    let file = ".svg"

    if (type.toLocaleLowerCase() === "png") {
      image = (await editor.renderer.toDataURL(template, {})) as string
      file = ".png"
    }

    const a = document.createElement("a")
    a.href = image
    a.download = `Noun${file}`
    a.click()
  }

  return (
    // @ts-ignore
    <Container
      $style={{
        height: "64px",
        background: theme.colors.mono100,
        fontFamily: "Inter",
        borderBottom: `1px solid ${theme.colors.mono200}`,
        display: "grid",
        padding: "0 1.25rem",
        gridTemplateColumns: "200px 1fr 240px",
        alignItems: "center",
      }}
    >
      <Logo size={32} fill={theme.colors.negative} />
      {/* <DesignTitle /> */}
      <div></div>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          justifyContent: "flex-end",
        }}
      >
        <StatefulTooltip
          placement={PLACEMENT.left}
          showArrow={true}
          accessibilityType={"tooltip"}
          content={objects.length !== 0 ? "" : "No Traits Selected"}
          overrides={{
            Inner: {
              style: {
                color: theme.colors.negative,
              },
            },
          }}
        >
          <Button
            size="compact"
            onClick={() => {
              if (objects.length !== 0) {
                setIsSidebarOpen(true)
                setActivePanel(PanelType.TRAITS)
              }
            }}
            colors={{
              backgroundColor: theme.colors.mono100,
              color: theme.colors.negative,
            }}
            kind={KIND.tertiary}
            overrides={{
              StartEnhancer: {
                style: {
                  marginRight: "4px",
                },
              },
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <TraitsIcon size={24} fill={theme.colors.negative} />
            {/* <span style={{ paddingLeft: "10px" }}>Traits</span> */}
          </Button>
        </StatefulTooltip>
        <Popover
          isOpen={isDownloadDropdownOpen}
          onClick={() => setIsDownloadDropdownOpen((s) => !s)}
          content={
            <StatefulMenu
              items={[{ label: "SVG" }, { label: "PNG" }]}
              overrides={{
                ListItem: {
                  style: {
                    color: theme.colors.primary,
                  },
                },
              }}
              onItemSelect={(a) => {
                setIsDownloadDropdownOpen(false)
                downloadNoun(a.item.label)
              }}
            />
          }
        >
          <Button
            size="compact"
            colors={{
              backgroundColor: theme.colors.mono100,
              color: theme.colors.negative,
            }}
            kind={KIND.tertiary}
            overrides={{
              StartEnhancer: {
                style: {
                  marginRight: "4px",
                },
              },
            }}
            style={{
              cursor: "pointer",
            }}
            endEnhancer={() => <TriangleDown size={24} />}
          >
            <DownloadIcon size={24} color={theme.colors.negative} />
            {/* <span style={{ paddingLeft: "10px" }}>Download</span> */}
          </Button>
        </Popover>
        <Button
          size="compact"
          onClick={() => setDisplayPreview(true)}
          colors={{
            backgroundColor: theme.colors.mono100,
            color: theme.colors.negative,
          }}
          kind={KIND.tertiary}
          overrides={{
            StartEnhancer: {
              style: {
                marginRight: "4px",
              },
            },
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <ViewIcon size={24} fill={theme.colors.negative} />
          {/* <span style={{ paddingLeft: "10px" }}>Preview</span> */}
        </Button>
      </Block>
    </Container>
  )
}
