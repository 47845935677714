function AccessoryIcon({ size, fill = "currentColor" }: { size: number; fill?: string }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8" y="20" width="1" height="1" fill={fill} />
      <rect x="8" y="18" width="1" height="1" fill={fill} />
      <rect x="6" y="18" width="1" height="1" fill={fill} />
      <rect x="7" y="18" width="1" height="1" fill={fill} />
      <rect x="9" y="17" width="1" height="1" fill={fill} />
      <rect x="9" y="16" width="1" height="1" fill={fill} />
      <rect x="9" y="15" width="1" height="1" fill={fill} />
      <rect x="6" y="14" width="1" height="1" fill={fill} />
      <rect x="6" y="13" width="1" height="1" fill={fill} />
      <rect x="6" y="12" width="1" height="1" fill={fill} />
      <rect x="3" y="11" width="1" height="1" fill={fill} />
      <rect x="3" y="10" width="1" height="1" fill={fill} />
      <rect x="3" y="9" width="1" height="1" fill={fill} />
      <rect x="7" y="14" width="1" height="1" fill={fill} />
      <rect x="7" y="13" width="1" height="1" fill={fill} />
      <rect x="7" y="12" width="1" height="1" fill={fill} />
      <rect x="4" y="11" width="1" height="1" fill={fill} />
      <rect x="4" y="10" width="1" height="1" fill={fill} />
      <rect x="4" y="9" width="1" height="1" fill={fill} />
      <rect x="8" y="14" width="1" height="1" fill={fill} />
      <rect x="8" y="13" width="1" height="1" fill={fill} />
      <rect x="8" y="12" width="1" height="1" fill={fill} />
      <rect x="5" y="11" width="1" height="1" fill={fill} />
      <rect x="5" y="10" width="1" height="1" fill={fill} />
      <rect x="5" y="9" width="1" height="1" fill={fill} />
      <rect x="9" y="14" width="1" height="1" fill={fill} />
      <rect x="9" y="13" width="1" height="1" fill={fill} />
      <rect x="9" y="12" width="1" height="1" fill={fill} />
      <rect x="6" y="11" width="1" height="1" fill={fill} />
      <rect x="6" y="10" width="1" height="1" fill={fill} />
      <rect x="6" y="9" width="1" height="1" fill={fill} />
      <rect x="10" y="14" width="1" height="1" fill={fill} />
      <rect x="10" y="13" width="1" height="1" fill={fill} />
      <rect x="10" y="12" width="1" height="1" fill={fill} />
      <rect x="7" y="11" width="1" height="1" fill={fill} />
      <rect x="7" y="10" width="1" height="1" fill={fill} />
      <rect x="7" y="9" width="1" height="1" fill={fill} />
      <rect x="11" y="14" width="1" height="1" fill={fill} />
      <rect x="11" y="13" width="1" height="1" fill={fill} />
      <rect x="11" y="12" width="1" height="1" fill={fill} />
      <rect x="8" y="11" width="1" height="1" fill={fill} />
      <rect x="8" y="10" width="1" height="1" fill={fill} />
      <rect x="8" y="9" width="1" height="1" fill={fill} />
      <rect x="12" y="14" width="1" height="1" fill={fill} />
      <rect x="12" y="13" width="1" height="1" fill={fill} />
      <rect x="12" y="12" width="1" height="1" fill={fill} />
      <rect x="9" y="11" width="1" height="1" fill={fill} />
      <rect x="9" y="10" width="1" height="1" fill={fill} />
      <rect x="9" y="9" width="1" height="1" fill={fill} />
      <rect x="12" y="8" width="1" height="1" fill={fill} />
      <rect x="12" y="7" width="1" height="1" fill={fill} />
      <rect x="12" y="6" width="1" height="1" fill={fill} />
      <rect x="13" y="14" width="1" height="1" fill={fill} />
      <rect x="13" y="13" width="1" height="1" fill={fill} />
      <rect x="13" y="12" width="1" height="1" fill={fill} />
      <rect x="10" y="11" width="1" height="1" fill={fill} />
      <rect x="10" y="10" width="1" height="1" fill={fill} />
      <rect x="10" y="9" width="1" height="1" fill={fill} />
      <rect x="13" y="8" width="1" height="1" fill={fill} />
      <rect x="13" y="7" width="1" height="1" fill={fill} />
      <rect x="13" y="6" width="1" height="1" fill={fill} />
      <rect x="14" y="14" width="1" height="1" fill={fill} />
      <rect x="14" y="13" width="1" height="1" fill={fill} />
      <rect x="14" y="12" width="1" height="1" fill={fill} />
      <rect x="11" y="11" width="1" height="1" fill={fill} />
      <rect x="11" y="10" width="1" height="1" fill={fill} />
      <rect x="11" y="9" width="1" height="1" fill={fill} />
      <rect x="14" y="8" width="1" height="1" fill={fill} />
      <rect x="14" y="7" width="1" height="1" fill={fill} />
      <rect x="14" y="6" width="1" height="1" fill={fill} />
      <rect x="15" y="14" width="1" height="1" fill={fill} />
      <rect x="15" y="13" width="1" height="1" fill={fill} />
      <rect x="15" y="12" width="1" height="1" fill={fill} />
      <rect x="12" y="11" width="1" height="1" fill={fill} />
      <rect x="12" y="10" width="1" height="1" fill={fill} />
      <rect x="12" y="9" width="1" height="1" fill={fill} />
      <rect x="15" y="8" width="1" height="1" fill={fill} />
      <rect x="18" y="11" width="1" height="1" fill={fill} />
      <rect x="15" y="7" width="1" height="1" fill={fill} />
      <rect x="18" y="10" width="1" height="1" fill={fill} />
      <rect x="15" y="6" width="1" height="1" fill={fill} />
      <rect x="18" y="9" width="1" height="1" fill={fill} />
      <rect x="15" y="5" width="1" height="1" fill={fill} />
      <rect x="15" y="4" width="1" height="1" fill={fill} />
      <rect x="15" y="3" width="1" height="1" fill={fill} />
      <rect x="16" y="14" width="1" height="1" fill={fill} />
      <rect x="16" y="13" width="1" height="1" fill={fill} />
      <rect x="16" y="12" width="1" height="1" fill={fill} />
      <rect x="13" y="11" width="1" height="1" fill={fill} />
      <rect x="13" y="10" width="1" height="1" fill={fill} />
      <rect x="13" y="9" width="1" height="1" fill={fill} />
      <rect x="16" y="8" width="1" height="1" fill={fill} />
      <rect x="19" y="11" width="1" height="1" fill={fill} />
      <rect x="16" y="7" width="1" height="1" fill={fill} />
      <rect x="19" y="10" width="1" height="1" fill={fill} />
      <rect x="16" y="6" width="1" height="1" fill={fill} />
      <rect x="19" y="9" width="1" height="1" fill={fill} />
      <rect x="16" y="5" width="1" height="1" fill={fill} />
      <rect x="16" y="4" width="1" height="1" fill={fill} />
      <rect x="16" y="3" width="1" height="1" fill={fill} />
      <rect x="17" y="14" width="1" height="1" fill={fill} />
      <rect x="17" y="13" width="1" height="1" fill={fill} />
      <rect x="17" y="12" width="1" height="1" fill={fill} />
      <rect x="14" y="11" width="1" height="1" fill={fill} />
      <rect x="14" y="10" width="1" height="1" fill={fill} />
      <rect x="14" y="9" width="1" height="1" fill={fill} />
      <rect x="17" y="8" width="1" height="1" fill={fill} />
      <rect x="20" y="11" width="1" height="1" fill={fill} />
      <rect x="17" y="7" width="1" height="1" fill={fill} />
      <rect x="20" y="10" width="1" height="1" fill={fill} />
      <rect x="17" y="6" width="1" height="1" fill={fill} />
      <rect x="20" y="9" width="1" height="1" fill={fill} />
      <rect x="17" y="5" width="1" height="1" fill={fill} />
      <rect x="17" y="4" width="1" height="1" fill={fill} />
      <rect x="17" y="3" width="1" height="1" fill={fill} />
      <rect x="10" y="17" width="1" height="1" fill={fill} />
      <rect x="10" y="16" width="1" height="1" fill={fill} />
      <rect x="10" y="15" width="1" height="1" fill={fill} />
      <rect x="11" y="17" width="1" height="1" fill={fill} />
      <rect x="11" y="16" width="1" height="1" fill={fill} />
      <rect x="11" y="15" width="1" height="1" fill={fill} />
      <rect x="12" y="17" width="1" height="1" fill={fill} />
      <rect x="12" y="16" width="1" height="1" fill={fill} />
      <rect x="12" y="15" width="1" height="1" fill={fill} />
      <rect x="13" y="17" width="1" height="1" fill={fill} />
      <rect x="13" y="16" width="1" height="1" fill={fill} />
      <rect x="13" y="15" width="1" height="1" fill={fill} />
      <rect x="14" y="17" width="1" height="1" fill={fill} />
      <rect x="14" y="16" width="1" height="1" fill={fill} />
      <rect x="14" y="15" width="1" height="1" fill={fill} />
      <rect x="15" y="17" width="1" height="1" fill={fill} />
      <rect x="15" y="16" width="1" height="1" fill={fill} />
      <rect x="15" y="15" width="1" height="1" fill={fill} />
      <rect x="16" y="17" width="1" height="1" fill={fill} />
      <rect x="16" y="16" width="1" height="1" fill={fill} />
      <rect x="16" y="15" width="1" height="1" fill={fill} />
      <rect x="17" y="17" width="1" height="1" fill={fill} />
      <rect x="17" y="16" width="1" height="1" fill={fill} />
      <rect x="17" y="15" width="1" height="1" fill={fill} />
      <rect x="6" y="20" width="1" height="1" fill={fill} />
      <rect x="6" y="19" width="1" height="1" fill={fill} />
      <rect x="7" y="19" width="1" height="1" fill={fill} />
      <rect x="8" y="19" width="1" height="1" fill={fill} />
      <rect x="7" y="20" width="1" height="1" fill={fill} />
    </svg>
  )
}

export default AccessoryIcon
