const mappings = ["body", "accessory", "head", "glasses"]

export const removePrefixAndTransform = (input: string, truncateAmount: number = 20): string => {
  for (const prefix of mappings) {
    const prefixWithHyphen = prefix + "-"

    if (input.startsWith(prefixWithHyphen)) {
      const withoutPrefix = input.slice(prefixWithHyphen.length)
      let transformed = withoutPrefix
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")

      // Truncate if length is more than truncateAmount characters
      if (transformed.length > truncateAmount) {
        transformed = transformed.slice(0, truncateAmount) + "..."
      }

      return transformed
    }
  }

  return input
}
