function Logo({
  size,
  fill = "#ffffff",
  theme,
  animate = false,
}: {
  size: number
  fill?: string
  theme?: string
  animate?: boolean
}) {
  return (
    <svg width={size} height={size} viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="3" width="1" height="1" fill={fill} className={animate ? `logo-${theme}-box-1` : ""}></rect>
      <rect y="3" width="1" height="1" fill={fill} className={animate ? `logo-${theme}-box-2` : ""}></rect>
      <rect y="2" width="1" height="1" fill={fill} className={animate ? `logo-${theme}-box-3` : ""}></rect>
      <rect y="1" width="1" height="1" fill={fill} className={animate ? `logo-${theme}-box-4` : ""}></rect>
      <rect x="1" width="1" height="1" fill={fill} className={animate ? `logo-${theme}-box-5` : ""}></rect>
      <rect x="2" width="1" height="1" fill={fill} className={animate ? `logo-${theme}-box-6` : ""}></rect>
      <rect x="3" width="1" height="1" fill={fill} className={animate ? `logo-${theme}-box-7` : ""}></rect>
      <rect x="3" y="1" width="1" height="1" fill={fill} className={animate ? `logo-${theme}-box-8` : ""}></rect>
    </svg>
  )
}

export default Logo
