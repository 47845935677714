"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapper = exports.split = exports.reverse = exports.base64Decode = exports.base64Encode = void 0;
const buffer_1 = require("buffer");
const base64Encode = (data) => {
    return buffer_1.Buffer.from(data, "utf8").toString("base64");
};
exports.base64Encode = base64Encode;
const base64Decode = (data) => {
    return buffer_1.Buffer.from(data, "base64").toString("utf8");
};
exports.base64Decode = base64Decode;
const reverse = (data) => {
    return data.split("").reverse().join("");
};
exports.reverse = reverse;
const split = (data) => {
    return data.match(/.{1,10}/g) || [];
};
exports.split = split;
const mapper = (data, mappings, seperator, joiner) => {
    return data.map((chunk) => {
        return chunk
            .split(seperator)
            .map((char) => {
            return mappings[char];
        })
            .join(joiner);
    });
};
exports.mapper = mapper;
