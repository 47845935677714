import React from "react"
import { useTranslation } from "react-i18next"
import { useStyletron, styled } from "baseui"
import { Block } from "baseui/block"
import { Select, TYPE, Value } from "baseui/select"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import RefreshIcon from "~/components/Icons/Refresh"
import Scrollable from "~/components/Scrollable"
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip"
import { useActiveObject, useEditor } from "@buildyournoun/react"
import { Trait, TraitOption } from "~/interfaces/common"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import { removePrefixAndTransform } from "~/utils/names"

export default function ({ type, data }: { type: string; data: Trait[] }) {
  const { t } = useTranslation("editor")
  const activeObject = useActiveObject() as any
  const editor = useEditor()
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const [css, theme] = useStyletron()

  const [selectedTrait, setSelectedTrait] = React.useState<TraitOption | null>(
    activeObject
      ? {
          name: removePrefixAndTransform(activeObject.name),
          trait: activeObject,
        }
      : null
  )

  const addTrait = React.useCallback(
    (trait: Trait) => {
      if (editor) {
        editor.objects.add(trait)
      }
    },
    [editor]
  )

  const getRandomTrait = () => {
    return data[Math.floor(Math.random() * data.length)]
  }

  const randomise = React.useCallback(() => {
    var trait = getRandomTrait()

    if (activeObject) {
      if (trait.name === activeObject.name) {
        trait = getRandomTrait()
      }
    }

    assignTrait(trait)
  }, [activeObject])

  const assignTrait = React.useCallback((trait: Trait) => {
    if (trait) {
      setSelectedTrait({
        name: removePrefixAndTransform(trait.name),
        trait: trait,
      } as TraitOption)

      addTrait(trait)
    }
  }, [])

  return (
    <Block $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          color: theme.colors.negative,
          fontFamily: "Inter",
          fontSize: "14px",
          justifyContent: "space-between",
          padding: "1.45rem",
          zIndex: 1,
          boxShadow: `0px 15px 0px -14px ${theme.colors.mono200}`,
        }}
      >
        <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
          <AngleDoubleLeft size={18} />
        </Block>
        <Block onClick={randomise} $style={{ cursor: "pointer", display: "flex" }}>
          <RefreshIcon size={18} />
          {/* <span style={{ paddingLeft: "10px" }}>Shuffle</span> */}
        </Block>
      </Block>
      <Block
        style={{
          gap: "8px",
          padding: "1.5rem",
          color: theme.colors.negative,
        }}
      >
        <Select
          options={data.map((trait) => ({
            name: removePrefixAndTransform(trait.name),
            trait: trait,
          }))}
          value={selectedTrait ? [selectedTrait] : []}
          overrides={{
            ControlContainer: {
              style: ({ $theme }) => ({
                outline: `${$theme.colors.mono200} 1px solid`,
                borderRadius: "0px",
                boxShadow: "none",
                color: theme.colors.negative,
              }),
            },
            Input: {
              style: () => ({
                fontFamily: "Dystopian",
                fontSize: "14px",
                color: theme.colors.negative,
              }),
            },
            ValueContainer: {
              style: () => ({
                fontFamily: "Dystopian",
                fontSize: "14px",
                color: theme.colors.negative,
              }),
            },
            Placeholder: {
              style: () => ({
                fontFamily: "Dystopian",
                fontSize: "14px",
                color: theme.colors.negative,
              }),
            },
            DropdownListItem: {
              style: () => ({
                fontFamily: "Dystopian",
                color: theme.colors.negative,
              }),
            },
            OptionContent: {
              style: () => ({
                fontFamily: "Dystopian",
                fontSize: "14px",
                color: theme.colors.negative,
              }),
            },
          }}
          labelKey="name"
          valueKey="name"
          noResultsMsg={`No ${type} found`}
          closeOnSelect={false}
          placeholder={`Search ${type}`}
          maxDropdownHeight="100px"
          type={TYPE.select}
          onChange={({ value }) => {
            if (value.length > 0) {
              assignTrait((value[0] as TraitOption).trait)
            } else {
              setSelectedTrait(null)
            }
          }}
        />
      </Block>
      <Scrollable>
        <Block $style={{ zIndex: 2 }}>
          <Block
            style={{
              display: "grid",
              gap: "8px",
              padding: "1.5rem",
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            {data.map((trait, index) => (
              <NounItem onClick={() => assignTrait(trait)} key={index} trait={trait} activeObject={activeObject} />
            ))}
          </Block>
        </Block>
      </Scrollable>
    </Block>
  )
}

function NounItem({
  trait,
  onClick,
  activeObject,
}: {
  trait: Trait
  onClick?: (option: any) => void
  activeObject: any
}) {
  const [css, theme] = useStyletron()

  return (
    <div
      onClick={onClick}
      className={css({
        position: "relative",
        border:
          activeObject && activeObject.name === trait.name
            ? `1px solid ${theme.colors.accent}`
            : `1px solid ${theme.colors.mono100}`,
        background:
          activeObject && activeObject.name === trait.name ? `${theme.colors.mono400}` : `${theme.colors.mono400}`,
        cursor: "pointer",
        overflow: "hidden",
        "::before:hover": {
          opacity: 1,
        },
      })}
    >
      <div
        className={css({
          background: theme.colors.accent100,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
          transition: "opacity 0.3s ease-in-out",
          height: "100%",
          width: "100%",
          ":hover": {
            opacity: 0.1,
          },
        })}
      ></div>
      <img
        src={trait.src}
        className={css({
          width: "100%",
          height: "100%",
          objectFit: "contain",
          pointerEvents: "none",
          verticalAlign: "middle",
        })}
      />
    </div>
  )
}
