import { fabric } from "fabric"
import Base from "./Base"
import shourcutsManager from "../utils/shourcutsManager"
import { LayerType } from "../common/constants"
import { ControllerOptions } from ".."

class Events extends Base {
  constructor(props: ControllerOptions) {
    super(props)
    this.initialize()
  }

  private initialize() {
    this.canvas.wrapperEl.tabIndex = 1
    this.canvas.wrapperEl.style.outline = "none"
    // @ts-ignore
    this.canvas.on({
      "mouse:up": this.handleSelection,
      "selection:cleared": this.handleSelection,
      "selection:updated": this.handleSelection,
    })
  }

  public destroy() {
    this.canvas.off({
      "mouse:up": this.handleSelection,
      "selection:cleared": this.handleSelection,
      "selection:updated": this.handleSelection,
    })
  }

  handleSelection = (target: fabric.IEvent) => {
    if (target) {
      this.state.setActiveObject(null)
      const initialSelection = this.canvas.getActiveObject() as any
      const isNotMultipleSelection =
        (initialSelection && initialSelection.type === LayerType.GROUP.toLowerCase()) ||
        (initialSelection && initialSelection.type === LayerType.STATIC_VECTOR)

      if (initialSelection && !isNotMultipleSelection && initialSelection._objects) {
        const filteredObjects = (initialSelection._objects as fabric.Object[]).filter((object) => {
          if (object.type === LayerType.BACKGROUND) {
            return false
          }
          return !object.locked
        })
        this.canvas.discardActiveObject()
        if (filteredObjects.length > 0) {
          if (filteredObjects.length === 1) {
            this.canvas.setActiveObject(filteredObjects[0])
            this.state.setActiveObject(filteredObjects[0])
          } else {
            this.canvas.discardActiveObject()
            this.state.setActiveObject(null)
          }
        }
      }
    } else {
      this.state.setActiveObject(null)
    }
    this.canvas.requestRenderAll()
  }
}

export default Events
