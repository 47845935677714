import { useStyletron } from "baseui"
import { BASE_ITEMS } from "~/constants/app-options"
import useAppContext from "~/hooks/useAppContext"
import { styled } from "baseui"
import { Plus } from "baseui/icon"
import { useEditor } from "@buildyournoun/react"
import Icons from "~/components/Icons"
import { useTranslation } from "react-i18next"
import useIsSidebarOpen from "~/hooks/useIsSidebarOpen"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import Scrollable from "~/components/Scrollable"
import { Block } from "baseui/block"

import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"

const Container = styled("div", (props) => ({}))

function PanelsList() {
  const { activePanel } = useAppContext()
  const { editorType, setEditorType } = useDesignEditorContext()
  const { t } = useTranslation("editor")
  const [css, theme] = useStyletron()

  return (
    <Container
      $style={{
        width: "100px",
        backgroundColor: theme.colors.mono500,
        display: "flex",
      }}
    >
      <Scrollable autoHide={true}>
        {BASE_ITEMS.map((panelListItem) => (
          <PanelListItem
            key={panelListItem.name}
            label={t(`panels.panelsList.${panelListItem.id}`)}
            name={panelListItem.name}
            icon={panelListItem.name}
            iconSize={panelListItem.iconSize}
            activePanel={activePanel}
          />
        ))}
        <Block
          onClick={() => {
            setEditorType("NONE")
          }}
          $style={{
            paddingTop: "1rem",
            width: "100px",
            height: "100px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            fontWeight: 800,
            fontFamily: "Inter",
            fontSize: "0.8rem",
            userSelect: "none",
            transition: "all 0.5s",
            gap: "0.1rem",
            ":hover": {
              cursor: "pointer",
              color: theme.colors.warning,
              transition: "all 1s",
            },
          }}
        >
          <AngleDoubleLeft size={24} color={theme.colors.negative} />
        </Block>
      </Scrollable>
    </Container>
  )
}

function PanelListItem({ label, icon, iconSize, activePanel, name }: any) {
  const editor = useEditor()
  const { setActivePanel } = useAppContext()
  const isSidebarOpen = useIsSidebarOpen()
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const [css, theme] = useStyletron()

  // @ts-ignore
  const Icon = Icons[icon]
  return (
    <Block
      id={"EditorPanelList"}
      onClick={() => {
        setIsSidebarOpen(true)
        setActivePanel(name)

        var tab = name.toLowerCase()
        if (name === "Traits" || name === "Background") {
          tab = "Default"
        }

        editor.objects.select(tab)
      }}
      $style={{
        width: "100px",
        height: "100px",
        background:
          name === activePanel ? (isSidebarOpen ? theme.colors.mono100 : theme.colors.mono500) : theme.colors.mono500,
        color:
          name === activePanel ? (isSidebarOpen ? theme.colors.accent : theme.colors.negative) : theme.colors.negative,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        fontWeight: 800,
        fontFamily: "Inter",
        fontSize: "0.8rem",
        userSelect: "none",
        transition: "all 0.5s",
        gap: "0.1rem",
        ":hover": {
          cursor: "pointer",
          color: name === activePanel ? theme.colors.accent100 : theme.colors.warning,
          transition: "all 1s",
        },
      }}
    >
      <Icon size={iconSize} color={theme.colors.negative} />
      {label}
    </Block>
  )
}

export default PanelsList
