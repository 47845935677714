function GlassesBYNIcon({ size, fill = "currentColor" }: { size: number; fill?: string }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="13" width="1" height="1" fill={fill} />
      <rect x="4" y="12" width="1" height="1" fill={fill} />
      <rect x="4" y="11" width="1" height="1" fill={fill} />
      <rect x="5" y="11" width="1" height="1" fill={fill} />
      <rect x="6" y="11" width="1" height="1" fill={fill} />
      <rect x="7" y="11" width="1" height="1" fill={fill} />
      <rect x="7" y="10" width="1" height="1" fill={fill} />
      <rect x="8" y="9" width="1" height="1" fill={fill} />
      <rect x="9" y="9" width="1" height="1" fill={fill} />
      <rect x="10" y="9" width="1" height="1" fill={fill} />
      <rect x="11" y="9" width="1" height="1" fill={fill} />
      <rect x="12" y="9" width="1" height="1" fill={fill} />
      <rect x="12" y="10" width="1" height="1" fill={fill} />
      <rect x="12" y="11" width="1" height="1" fill={fill} />
      <rect x="12" y="12" width="1" height="1" fill={fill} />
      <rect x="12" y="13" width="1" height="1" fill={fill} />
      <rect x="9" y="14" width="1" height="1" fill={fill} />
      <rect x="8" y="14" width="1" height="1" fill={fill} />
      <rect x="7" y="14" width="1" height="1" fill={fill} />
      <rect x="7" y="13" width="1" height="1" fill={fill} />
      <rect x="7" y="12" width="1" height="1" fill={fill} />
      <rect x="13" y="11" width="1" height="1" fill={fill} />
      <rect x="14" y="10" width="1" height="1" fill={fill} />
      <rect x="14" y="11" width="1" height="1" fill={fill} />
      <rect x="14" y="12" width="1" height="1" fill={fill} />
      <rect x="14" y="13" width="1" height="1" fill={fill} />
      <rect x="14" y="14" width="1" height="1" fill={fill} />
      <rect x="15" y="14" width="1" height="1" fill={fill} />
      <rect x="16" y="14" width="1" height="1" fill={fill} />
      <rect x="19" y="13" width="1" height="1" fill={fill} />
      <rect x="19" y="12" width="1" height="1" fill={fill} />
      <rect x="19" y="11" width="1" height="1" fill={fill} />
      <rect x="19" y="10" width="1" height="1" fill={fill} />
      <rect x="19" y="9" width="1" height="1" fill={fill} />
      <rect x="18" y="9" width="1" height="1" fill={fill} />
      <rect x="17" y="9" width="1" height="1" fill={fill} />
      <rect x="16" y="9" width="1" height="1" fill={fill} />
      <rect x="15" y="9" width="1" height="1" fill={fill} />
      <rect x="11" y="10" width="1" height="1" fill={fill} />
      <rect x="10" y="10" width="1" height="1" fill={fill} />
      <rect x="10" y="11" width="1" height="1" fill={fill} />
      <rect x="10" y="12" width="1" height="1" fill={fill} />
      <rect x="10" y="13" width="1" height="1" fill={fill} />
      <rect x="11" y="13" width="1" height="1" fill={fill} />
      <rect x="11" y="12" width="1" height="1" fill={fill} />
      <rect x="11" y="11" width="1" height="1" fill={fill} />
      <rect x="17" y="10" width="1" height="1" fill={fill} />
      <rect x="18" y="10" width="1" height="1" fill={fill} />
      <rect x="17" y="11" width="1" height="1" fill={fill} />
      <rect x="18" y="11" width="1" height="1" fill={fill} />
      <rect x="17" y="12" width="1" height="1" fill={fill} />
      <rect x="17" y="13" width="1" height="1" fill={fill} />
      <rect x="18" y="13" width="1" height="1" fill={fill} />
      <rect x="18" y="12" width="1" height="1" fill={fill} />
    </svg>
  )
}

export default GlassesBYNIcon
