import { createLightTheme, lightThemePrimitives } from "baseui"

const myThemePrimitives = {
  ...lightThemePrimitives,
  mono100: "#ffffff",
  mono200: "#e6ebec",
  mono300: "#ededed",
  mono400: "#dfdfdf",
  mono500: "#f6f6f6",
  accent: "#2dd05a",
  accent100: "#dec642",
  negative: "#000000",
  warning: "#b92c2c",
  warning500: "#7e5454",
}

export default createLightTheme(myThemePrimitives, {})
